import {Box, Typography} from "@material-ui/core";
import Button from "common/components/Button";
import Table from "common/components/table";
import MoneyInput from "../../components/MoneyInput";
import {useStyles} from "./styles";
import {PlanButton} from "./components/PlanButton";
import useInsurance from "./hooks/useInsurance";
//import {getImages} from "./utils";
import {idtoplan, planList, tableConfig} from "./constants";


function Insurance() {
  const styles = useStyles();
  const {
    allPlan,
    insuranceMoney,
    handleMoneyChange,
    plan,
    handlePlanChange,
    handlePageChange,
  } = useInsurance();

  return (
    <Box marginTop="20px">
      <Typography variant="h1" color="primary">
        วางแผนเกษียณ
      </Typography>
      <MoneyInput value={insuranceMoney} onChange={handleMoneyChange} />
      <Table {...tableConfig} data={allPlan} />

      {/* Select Plan */}
      <div>
        <Typography variant="body1" style={{margin: "10px 0 10px 0"}}>
          เลือกผลประโยชน์ความคุ้มครอง
        </Typography>
        <div className={styles.planButtonContainer}>
          {planList.map((text, idx) => (
            <PlanButton
              key={idx}
              handleClick={handlePlanChange(idx)}
              text={text}
              isSelected={plan === String(idx + 1)}
            />
          ))}
        </div>
        <img src={`/retirementPlan/${idtoplan[plan]}.png`} style={{width: "100%"}} />
      </div>

      {/* Change Page */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexWrap: "wrap",
        }}
      >
        <Button onClick={handlePageChange("mutualFund")}>กองทุนรวม</Button>
        <Button onClick={handlePageChange("result")}>สรุปผล</Button>
      </div>
    </Box>
  );
}

export default Insurance;
