import {Typography} from "@material-ui/core";
import Button from "common/components/Button";
import color from "config/color";
import useDialog from "hooks/useDialog";
import {useRetirementPlanContext} from "pages/retirementPlan/context/retirementPlanContext";
import React, {useEffect, useState} from "react";
import FundChart from "../../../../components/FundChart";
import Investment from "../../../../components/Investment";
import {getAssetType} from "../../../../utils/fetch";

function MutualFund() {
  const {temp} = useRetirementPlanContext();
  const {Dialog, openDialog} = useDialog(Investment);
  const sampleDict: {[key: string]: string} = {};
  const [assetClassDict, setAssetClassDict] = useState(sampleDict);
  const percents: {[key: number]: number} = {};
  useEffect(() => {
    getAsset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getAsset = async () => {
    setAssetClassDict(await getAssetType());
  };

  let allweight = 0;
  temp.fund_port.lists.forEach((asset) => {
    asset.a.forEach((percent) => {
      const aType: number = parseFloat(Object.keys(percent)[0]);
      const aPercent = percent[aType];
      if (!percents[aType]) {
        percents[aType] = 0;
      }
      percents[aType] += aPercent * asset.w;
    });
    allweight += asset.w;
  });
  const percentList: {name: string; percent: number}[] = [];
  for (const [key, value] of Object.entries(percents)) {
    percentList.push({
      name: assetClassDict[key],
      percent: parseFloat((value / allweight).toFixed(2)),
    });
  }
  const MutualFundItem = (props: {name: string; value: number | string}) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexGrow: 1,
        margin: "10px",
      }}
    >
      <Typography variant="body1" style={{flexGrow: 1, textAlign: "center"}}>
        {props.name}
      </Typography>
      <Typography
        variant="body1"
        style={{
          backgroundColor: color.grey[100],
          padding: "10px",
          borderRadius: "10px",
          flexGrow: 1,
          textAlign: "center",
        }}
      >
        {props.value.toLocaleString()}
      </Typography>
    </div>
  );

  if (temp.fund_port.port_id === -1) {
    return (
      <div style={{flexGrow: 1, margin: "10px 0 20px 0"}}>
        <Typography
          variant="h3"
          style={{textAlign: "center", marginBottom: "10px"}}
        >
          กองทุนรวม
        </Typography>
        <Typography variant="body1" style={{textAlign: "center"}}>
          ไม่ได้เลือกข้อมูลในส่วนนี้
        </Typography>
      </div>
    );
  }

  return (
    <div style={{flexGrow: 1, margin: "10px 0 20px 0"}}>
      <Typography
        variant="h3"
        style={{textAlign: "center", marginBottom: "10px"}}
      >
        กองทุนรวม
      </Typography>
      <FundChart
        data={percentList}
        label
        name={
          temp.fund_port.name === "custom" ? "แบบกำหนดเอง" : temp.fund_port.name
        }
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <MutualFundItem
          name="ผลตอบแทนที่คาดหวัง"
          value={temp.fund_port.stat.expected}
        />
        <MutualFundItem name="ความผันผวน" value={temp.fund_port.stat.sd} />
      </div>
      <Button style={{display: "block", margin: "auto"}} onClick={openDialog}>
        มูลค่าการลงทุนรายปี
      </Button>
      <Dialog />
    </div>
  );
}

export default MutualFund;
