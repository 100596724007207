import {ICellStyle} from "common/components/table/props";
import {IIndex} from "../props";

// Get cell's style
const useCellStyle = (
  index: IIndex,
  cellStyle: ICellStyle | undefined
): React.CSSProperties | undefined => {
  const {row, column} = index;

  if (row === 0) return cellStyle?.headStyle;
  if (column === 0) return cellStyle?.rowHeadStyle;
};

export default useCellStyle;
