import {FormikContext, useField} from "formik";
import React, {useContext} from "react";

function withFormik<P extends object>(Component: (props: P) => JSX.Element) {
  type IProps = Omit<P, "value" | "onChange"> & {
    name?: string;
    value?: any;
    onChange?: (value: any) => void;
  };

  return (props: IProps) => {
    const {name, value, onChange} = props;

    // Formik Input
    const formikContext = useContext(FormikContext);
    if (formikContext && name) {
      const [field, , helpers] = useField(name);
      return (
        <Component
          {...(props as P)}
          field={field}
          value={field.value}
          onChange={helpers.setValue}
        />
      );
    }

    // Error: Not Formik but doesn't pass `value` and `onChange` props
    if (value === undefined || !onChange) {
      throw new Error(
        "You must pass `value` and `onChange` props if you use `" +
          Component.name +
          "` component outside of Formik or pass `name` prop to use the component inside of formik"
      );
    }

    // Normal Input
    return <Component {...(props as P)} />;
  };
}

export default withFormik;
