import RiskAssessment from "pages/finanCheck/pages/riskAssessment";
import RequiredMoney from "pages/finanCheck/pages/requiredMoney";
import Result from "pages/finanCheck/pages/result";
import {IPageRoutes} from "routes";
import RiskAssessmentResult from "pages/finanCheck/pages/riskAssessmentResult";
import PreparedMoney from "pages/finanCheck/pages/preparedMoney";

const pageRoutes: IPageRoutes = {
  default: {
    component: RiskAssessment,
    type: "form",
  },
  riskAssessment: {
    component: RiskAssessment,
    type: "form",
  },
  riskAssessmentResult: {
    component: RiskAssessmentResult,
    type: "form",
  },
  requiredMoney: {
    component: RequiredMoney,
    type: "form",
  },
  preparedMoney: {
    component: PreparedMoney,
    type: "form",
  },
  result: {
    component: Result,
    type: "result",
  },
};

export default pageRoutes;
