import {Typography} from "@material-ui/core";
import Button from "common/components/Button";
import React from "react";
import {riskAssessmentQuestions} from "../../constants";
import Question from "./components/Question";
import QuestionHead from "./components/QuestionHead";
import useRiskAssessment from "./hooks/useRiskAssessment";
import {useStyles} from "./styles";

function RiskAssessment() {
  const styles = useStyles();
  const {isPrepared, setPrepared, isFilled, handleSubmit, patron, setPatron} =
    useRiskAssessment();

  const questionList = riskAssessmentQuestions.map(({question}, index) => (
    <Question
      text={question}
      isPrepared={isPrepared[index]}
      setPrepared={setPrepared(index)}
      choices={["เตรียมพร้อมแล้ว", "ยังไม่เตรียม"]}
      hideText
      key={index}
    />
  ));

  return (
    <div className={styles.container}>
      <Typography variant="h2" className={styles.title}>
        แบบประเมินการเตรียมพร้อมทางการเงินสำหรับความเสี่ยงส่วนบุคคล
      </Typography>

      <Question
        text={"ท่านมีคนที่ต้องอุปการะหรือดูแลหรือไม่"}
        isPrepared={patron}
        setPrepared={setPatron}
        choices={["มี", "ไม่มี"]}
      />

      {/* Question */}
      <QuestionHead />
      {questionList}

      {/* Submit */}
      <Button
        className={styles.button}
        disabled={!isFilled}
        onClick={handleSubmit}
      >
        แสดงผล
      </Button>
    </div>
  );
}

export default RiskAssessment;
