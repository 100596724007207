import {Grid, Typography} from "@material-ui/core";
import React from "react";
import FormContainer from "common/components/FormContainer";
import FormItem from "./components/FormItem";
import GenderInput from "common/components/GenderInput";
import NumberInput from "common/components/NumberInput";
import {Form, Formik} from "formik";
import Button from "common/components/Button";
import {useStyles} from "./styles";
import {useForm} from "./hooks/useForm";

function SumAssuredForm() {
  const styles = useStyles();
  const {initialValues, handleSubmit} = useForm();
  return (
    <div className={styles.container}>
      <Typography variant="h1" color="primary">
        ทุนประกันชีวิตที่เหมาะสม
      </Typography>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <div className={styles.content}>
            <FormContainer name="กรอกข้อมูลสำคัญ" style={{flexGrow: 1}}>
              <Grid container spacing={2}>
                <FormItem
                  xs={6}
                  label="เพศ"
                  input={<GenderInput name="gender" />}
                />
                <FormItem
                  xs={6}
                  label="อายุ"
                  input={<NumberInput name="age" />}
                />
                <FormItem
                  xs={12}
                  label="รายรับ"
                  unit="บาท/เดือน"
                  input={<NumberInput name="income" />}
                />
                <FormItem
                  xs={12}
                  label="รายจ่าย"
                  unit="บาท/เดือน"
                  input={<NumberInput name="outcome" />}
                />
                <FormItem
                  xs={12}
                  label="หนี้สิน"
                  unit="บาท"
                  input={<NumberInput name="debt" />}
                />
                <FormItem
                  xs={6}
                  label="ระยะเวลาปรับตัว"
                  input={<NumberInput name="adaptPeriod" type="float" />}
                />
                <FormItem
                  xs={6}
                  label="อัตราเงินเฟ้อ"
                  input={<NumberInput name="inflaRate" type="float" />}
                />
              </Grid>
            </FormContainer>
            <Button type="submit" className={styles.button}>
              ประเมินผล
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}
export default SumAssuredForm;
