import Button from "common/components/Button";
import color from "config/color";
import {useStyles} from "./styles";
import {PlanButtonProps} from "./types";

export const PlanButton = (props: PlanButtonProps) => {
  const {text, handleClick, isSelected} = props;
  const styles = useStyles();
  return (
    <Button
      className={styles.planButton}
      style={
        isSelected ? {} : {backgroundColor: color.blue[100], color: "black"}
      }
      onClick={handleClick}
    >
      {text}
    </Button>
  );
};
