import Form from "pages/healthCare/pages/form";
import Result from "pages/healthCare/pages/result";
import {IPageRoutes} from "routes";

const pageRoutes: IPageRoutes = {
  default: {
    type: "form",
    component: Form,
  },
  form: {
    type: "form",
    component: Form,
  },
  result: {
    component: Result,
    type: "result",
  },
};
export default pageRoutes;
