import {makeStyles} from "@material-ui/core";
import color from "config/color";

export const useStyles = makeStyles(() => ({
  plusButton: {
    backgroundColor: color.blue[400],
    "&:hover": {
      backgroundColor: color.blue[400],
    },
  },
  container: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
  },
}));
