import {Box, Typography} from "@material-ui/core";
import Button from "common/components/Button";
import FormContainer from "common/components/FormContainer";
import GenderInput from "common/components/GenderInput";
import NumberInput from "common/components/NumberInput";
import SelectInput from "common/components/SelectInput";
import {Form, Formik, useField, useFormikContext} from "formik";
import useDialog from "hooks/useDialog";
import React from "react";
import RPQ from "../../components/RPQ";
import AgeInput from "./components/AgeInput";
import FormItem from "./components/FormItem";
import SliderInput from "./components/SliderInput";
import {risk_options} from "./constants";
import {useStyles} from "./styles";
import SavingChoiceButton from "./components/SavingChoiceButton";
import {getLowestRetirement} from "./utils";
import useForm from "./hooks/useForm";
import {IRetirePlan} from "pages/retirementPlan/types";

const RetirementPlanForm = () => {
  const styles = useStyles();

  const {values}: {values: IRetirePlan} = useFormikContext();
  const [, , page] = useField("page");

  const {Dialog, openDialog} = useDialog(RPQ);

  const Questions = (
    <Box display="flex" flexWrap="wrap">
      <FormItem
        label="เพศ"
        width="300px"
        input={<GenderInput name="gender" />}
      />
      <div className={styles.ageInput}>
        <AgeInput label="อายุปัจจุบัน" name="age" start />
        <AgeInput label="อายุเกษียณ" name="retriment_age" />
        <AgeInput label="สิ้นอายุขัย " name="death_age" end />
      </div>
      <FormItem
        label="รายจ่ายปัจจุบัน"
        width="450px"
        input={<NumberInput name="outcome" unit="บาท/เดือน" />}
      />
      <FormItem
        label="อัตราเงินเฟ้อ"
        width="250px"
        input={<NumberInput name="inflationrate" type="float" unit="%" />}
      />
      <FormItem
        label="เงินบำนาญขั้นต่ำที่ควรมี"
        width="500px"
        input={
          <Box
            display="flex"
            width="100%"
            textAlign="center"
            padding="0 10px 0 10px"
          >
            <Typography style={{flexGrow: 1}}>
              {getLowestRetirement(values)}
            </Typography>
            <Typography>บาท/เดือน</Typography>
          </Box>
        }
      />
      <FormItem
        label="เงินบำนาญที่ต้องการมี"
        width="500px"
        input={<NumberInput name="retryment_rq" unit="บาท/เดือน" />}
      />
      <FormItem
        label="ความเสี่ยงจากการลงทุนที่รับได้"
        width="600px"
        input={<SelectInput name="risk_accepted" options={risk_options} />}
      />
      <Button className={styles.RPQ} onClick={openDialog}>
        แบบประเมินความเสี่ยงที่ยอมรับได้
      </Button>
      <SliderInput name="need_insurance" />
    </Box>
  );

  return (
    <Box marginTop="20px">
      <Typography variant="h1" color="primary">
        วางแผนเกษียณ
      </Typography>

      <FormContainer name="กรอกข้อมูลสำคัญ">{Questions}</FormContainer>
      <SavingChoiceButton
        changePage={(newPage) => {
          page.setValue(newPage);
        }}
      />

      <Dialog />
    </Box>
  );
};

const Wrapper = () => {
  const {initialValues, handleClick} = useForm();
  return (
    <Formik initialValues={{...initialValues, page: ""}} onSubmit={handleClick}>
      <Form>
        <RetirementPlanForm />
      </Form>
    </Formik>
  );
};

export default Wrapper;
