import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  radio: {
    alignSelf: "center",
  },
  radioContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
