import {makeStyles, ThemeProvider} from "@material-ui/core";
import React, {useState} from "react";
import theme from "config/theme";
import {v4} from "uuid";
import {MenuRoutes} from "hooks/useRouter";
import {IData} from "common/interface/interface";
import Header from "common/components/shell/header";
import Sidebar from "common/components/shell/sidebar";
import {dataContext} from "common/context/context";

const useStyles = makeStyles({
  appContainer: {
    minHeight: "100vh",
    display: "flex",
    minWidth: "280px",
    maxWidth: "100vw",
    flexDirection: "column",
  },
  bodyContainer: {
    flexGrow: 1,
    display: "flex",
    minHeight: "100%",
  },
  contentContainer: {
    width: "100%",
    maxWidth: "1200px",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "10px",
    boxSizing: "border-box",
    overflowX: "hidden",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
      maxWidth: "1300px",
    },
  },
});

function App() {
  const currentVersion = "1.0";
  const styles = useStyles();
  let tempDataStore = localStorage.getItem("user_data");
  let tempData: IData = {};
  if (
    tempDataStore !== null &&
    localStorage.getItem("version") === currentVersion
  ) {
    tempData = JSON.parse(tempDataStore);
  }
  const [data, setBigData] = useState(tempData);
  let device_id = localStorage.getItem("device_id");
  if (device_id === null) {
    device_id = v4();
    localStorage.setItem("device_id", device_id);
  }
  const setData = (data: IData) => {
    localStorage.setItem("user_data", JSON.stringify(data));
    localStorage.setItem("version", currentVersion);
    setBigData(data);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.appContainer}>
        <Header />
        <div className={styles.bodyContainer}>
          <Sidebar />
          <div className={styles.contentContainer}>
            <dataContext.Provider value={{data, setData}}>
              <MenuRoutes />
            </dataContext.Provider>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
