import {Box, Hidden, makeStyles, Theme} from "@material-ui/core";
import color from "config/color";
import SidebarItem from "./SidebarItem";
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: color.blue[500],
    minHeight: "100%",
  },
}));
function Sidebar() {
  const styles = useStyles();
  return (
    <Hidden smDown>
      <Box className={styles.container}>
        <SidebarItem id={-1} />
        <SidebarItem id={0} />
        <SidebarItem id={1} />
        <SidebarItem id={2} />
      </Box>
    </Hidden>
  );
}
export default Sidebar;
