import {dataContext} from "common/context/context";
import useRouter from "hooks/useRouter";
import {useRetirementPlanContext} from "pages/retirementPlan/context/retirementPlanContext";
import {useContext} from "react";
import {IFormField} from "../types";

const useForm = () => {
  const {setPage} = useRouter();
  const {data, setData} = useContext(dataContext);
  const {temp, setTemp} = useRetirementPlanContext();
  const {
    gender,
    age,
    retriment_age,
    death_age,
    outcome,
    inflationrate,
    retryment_rq,
    need_insurance,
  } = temp;

  const initialValues: IFormField = {
    gender,
    age,
    retriment_age,
    death_age,
    outcome,
    inflationrate,
    retryment_rq,
    need_insurance,
    risk_accepted:
      !temp.risk_accepted || temp.risk_accepted == -1
        ? "0"
        : temp.risk_accepted.toString(),
  };

  const handleClick = (values: IFormField & {page: string}) => {
    let need_insurance_money = temp.need_insurance_money;
    let need_mutualfund_money = temp.need_mutualfund_money;
    if (
      need_insurance_money == -1 ||
      temp.need_insurance != values.need_insurance ||
      temp.retryment_rq != values.retryment_rq
    ) {
      need_insurance_money =
        (values.need_insurance * values.retryment_rq) / 100;
      need_mutualfund_money =
        ((100 - values.need_insurance) * values.retryment_rq) / 100;
    }
    const updateTemp = {
      gender: values.gender,
      age: values.age,
      retriment_age: values.retriment_age,
      death_age: values.death_age,
      outcome: values.outcome,
      inflationrate: values.inflationrate,
      retryment_rq: values.retryment_rq,
      need_insurance: values.need_insurance,
      need_insurance_money,
      need_mutualfund: 100 - values.need_insurance,
      need_mutualfund_money,
      risk_accepted: parseInt(values.risk_accepted),
      risk_data: {
        risk_form_id: 0,
        risk_score: 0,
        risk_assessed: 0,
      },
    };
    setTemp({...temp, ...updateTemp});
    setData({...data, retirePlan: {...temp, ...updateTemp}});
    setPage(values.page);
  };
  return {initialValues, handleClick};
};

export default useForm;
