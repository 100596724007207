import Table from "common/components/table";
import {label, tableKey} from "pages/healthCare/constants";
import {IPlanList} from "pages/healthCare/types";
import React from "react";
import {cellStyles} from "./styles";

interface Props {
  planList: IPlanList;
}

function ResultTable(props: Props) {
  const {planList} = props;
  return (
    <Table
      type="column"
      tableKey={tableKey}
      label={label}
      data={planList.data}
      cellStyles={cellStyles}
    />
  );
}
export default ResultTable;
