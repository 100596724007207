const color = {
  grey: {
    "100": "#F3F3F5",
    "200": "#B8B8B8",
    "300": "#94959E",
    "400": "#545454",
  },
  blue: {
    "100": "#CAEAF9",
    "200": "#6BCEF7",
    "300": "#54B4E6",
    "400": "#4496D2",
    "500": "#2767AE",
  },
};

export default color;
