import Button from "common/components/Button";
import React from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import Port from "./components/Port";
import {RiArrowRightSLine, RiArrowLeftSLine} from "react-icons/ri";
import {Hidden, Typography} from "@material-ui/core";
import useMutualFund from "./hooks/useMutualFund";
import {useStyles} from "./styles";

function MutualFund() {
  const styles = useStyles();
  const {portsData, handlePageChange} = useMutualFund();

  const ports = portsData.map((data) => <Port {...data} />);

  return (
    <div style={{marginTop: "20px"}}>
      <Typography variant="h1" color="primary" style={{marginBottom: "20px"}}>
        วางแผนเกษียณ
      </Typography>
      <Hidden smDown>
        <ScrollMenu
          data={ports}
          arrowLeft={
            <div>
              <RiArrowLeftSLine size="2.5rem" />
            </div>
          }
          arrowRight={<RiArrowRightSLine size="2.5rem" />}
          wrapperStyle={{
            margin: "auto",
          }}
          innerWrapperStyle={{
            display: "flex",
            alignItems: "stretch",
          }}
          itemStyle={{
            display: "flex",
            alignItems: "stretch",
          }}
        />
      </Hidden>
      <Hidden mdUp>{ports}</Hidden>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "10px",
        }}
      >
        <Button
          onClick={handlePageChange("customMutualFund")}
          className={styles.button}
        >
          จัดพอร์ตด้วยตัวเอง
        </Button>
        <Button onClick={handlePageChange("result")} className={styles.button}>
          ตกลง
        </Button>
      </div>
    </div>
  );
}

export default MutualFund;
