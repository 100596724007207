import {makeStyles, Theme} from "@material-ui/core";
import useRouter from "hooks/useRouter";
import color from "config/color";
interface StyleProps {
  filter: string;
  backgroundColor: string;
  cursor: string;
}
const useStyles = makeStyles((theme: Theme) => ({
  img: {
    width: "70px",
    filter: (props: StyleProps) => props.filter,
  },
  container: {
    marginLeft: "10px",
    padding: "10px",
    backgroundColor: (props: StyleProps) => props.backgroundColor,
    borderRadius: " 20px 0 0 20px",
    cursor: (props: StyleProps) => props.cursor,
  },
}));
function SidebarItem(props: {id: number}) {
  const {id} = props;
  const {process, latestProcess, setProcess} = useRouter();
  const styles = useStyles({
    backgroundColor: id === process ? color.blue[100] : "",
    filter:
      id === process
        ? "invert(1)"
        : id > latestProcess
        ? "invert(42%) sepia(6%) saturate(179%) hue-rotate(177deg) brightness(84%) contrast(86%)"
        : "",
    cursor: id != -1 && id <= latestProcess ? "pointer" : "",
  });
  const handleClick = () => {
    if (id !== -1 && id <= latestProcess) setProcess(id);
  };
  return (
    <div className={styles.container}>
      <img
        src={require("../../../../local/shell/" + String(id) + ".png").default}
        className={styles.img}
        onClick={() => handleClick()}
        alt=""
      ></img>
    </div>
  );
}
export default SidebarItem;
