import {makeStyles} from "@material-ui/core";
import color from "config/color";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },

  // Inner part

  cell: {
    margin: "2px",
    width: "100%",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },

  highlight: {
    backgroundColor: `${color.blue[300]}88`,
  },

  // Cell Container
  cellContainer: {
    flexGrow: 1,
    width: "0",
    minHeight: "60px",
    backgroundColor: color.grey[100],
    border: "1px solid white",
    display: "flex",
  },

  blank: {
    flexGrow: 1,
    flexBasis: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  rowHead: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexBasis: "unset",
      borderRadius: "0 0 0 0 !important",
    },
  },

  // BorderRadius

  topLeft: {
    borderRadius: "20px 0 0 0",
  },

  topRight: {
    borderRadius: "0 20px 0 0",
  },

  bottomRight: {
    borderRadius: "0 0 20px 0",
  },

  bottomLeft: {
    borderRadius: "0 0 0 20px",
  },

  smBottomLeft: {
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0 0 0 20px",
    },
  },
}));
