import {useCallback, useState} from "react";

const useModal = (onClose?: () => void) => {
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
    if (onClose) onClose();
  }, [onClose]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return {open, handleClose, handleOpen};
};

export default useModal;
