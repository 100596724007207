import {IHealthCare} from "./types";

export const defaultValues: IHealthCare = {
  sex: "male",
  age: 30,
  hospital: "Premium",
  disease: 0,
};

export const tableKey = ["plan", "room", "health", "disease", "premium"];

export const label = {
  room: "ค่าห้องพัก",
  health: "ค่ารักษาแบบเหมาจ่าย",
  disease: "ความคุ้มครองโรคร้ายแรง",
  premium: "เบี้ยปีแรก",
};
