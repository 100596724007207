import color from "config/color";

export const idtoplan: {[key: string]: string} = {
  "1": "AIA_ANNUITY_FIX",
  "2": "MUANG_THAI_8560",
  "3": "MUANG_THAI_9960",
};
export const planimgname = ["", "AIA_ANNUITY_FIX", "MUANG_THAI_8560", "MUANG_THAI_9960"];

export const planList = ["AIA ANNUITY FIX", "เมืองไทย 8560", "เมืองไทย 9960"];

export const tableConfig = {
  type: "column" as "column" | "row",
  cellStyles: {
    headStyle: {
      borderRadius: "20px 20px 0 0",
      backgroundColor: color.blue[200],
    },
    rowHeadStyle: {
      color: "white",
      backgroundColor: color.blue[400],
    },
  },
  tableKey: ["plan", "premium", "pay_period", "period"],
  label: {
    plan: "",
    premium: "เบี้ยประกัน",
    pay_period: "ระยะเวลาจ่าย",
    period: "ช่วงอายุรับเงินบำนาญ",
  },
};
