import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  input: {
    flexGrow: 1,
    textAlign: "center",
    alignItems: "center",
    minHeight: "40px",
    borderRadius: "10px",
    display: "flex",
    paddingLeft: "10px",
    paddingRight: "10px",
    overflow: "hidden",
    background: "white",
  },
}));
