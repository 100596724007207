import {makeStyles} from "@material-ui/core";
import color from "config/color";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "900px",
    margin: "auto",
  },
  title: {
    margin: "20px 0",
    color: "white",
    background: color.blue[400],
    padding: "10px",
    textAlign: "center",
    borderRadius: "20px",
  },
  button: {
    display: "block",
    margin: "auto",
  },
}));
