import React, {createContext, useState} from "react";

interface IRouteContext {
  menu: string;
  setMenu: (menu: string) => void;
  process: number;
  setProcess: (process: number) => void;
  latestProcess: number;
  setLatestProcess: (latestProcess: number) => void;
  page: string;
  setPage: (menu: string) => void;
}
const RouteContext = createContext<IRouteContext>({
  menu: "default",
} as IRouteContext);
const RouteProvider = (props: {children: React.ReactNode}) => {
  const [menu, setMenu] = useState("default");
  const [page, setPage] = useState("default");
  const [process, setProcess] = useState(0);
  const [latestProcess, setLatestProcess] = useState(0);

  const newSetProcess = (newProcess: number) => {
    setLatestProcess(Math.max(latestProcess, newProcess));
    setProcess(newProcess);
  };

  return (
    <RouteContext.Provider
      value={{
        menu,
        setMenu,
        process,
        setProcess: newSetProcess,
        latestProcess,
        setLatestProcess,
        page,
        setPage,
      }}
    >
      {props.children}
    </RouteContext.Provider>
  );
};

export {RouteContext, RouteProvider};
