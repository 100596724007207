import {IRiskAssessmentQuestion} from "./types";

export const riskAssessmentQuestions: IRiskAssessmentQuestion[] = [
  {
    question: "1. ค่าใช้จ่ายหากเจ็บป่วยจนต้องเข้าพักรักษาตัวในโรงพยาบาล",
    weight: [1, 1],
  },
  {
    question: "2. ค่าใช้จ่ายหากประสบอุบัติเหตุ",
    weight: [1, 1],
  },
  {
    question: "3. ค่าใช้จ่ายหากเกิดโรคร้ายแรง เช่น โรคหัวใจ โรคมะเร็ง",
    weight: [1, 1],
  },
  {
    question: "4. รายได้ที่สม่ำเสมอหากต้องหยุดพักรักษาตัวในโรงพยาบาล",
    weight: [2, 1],
  },
  {
    question: "5. ค่าใช้จ่ายเพื่อดำเนินชีวิต กรณีพิการ ไม่สามารถทำงานได้",
    weight: [2, 1],
  },
  {
    question: "6. ค่าใช้จ่ายเพื่อซ่อมแซม/ชดเชยทรัพย์สิน หากเกิดความเสียหาย",
    weight: [1, 1],
  },
  {
    question: "7. รายได้หลังเกษียณให้มีเพียงพอจนถึงันสุดท้ายของชีวิต",
    weight: [2, 1],
  },
  {
    question: "8. เงินทุนสำหรับจัดการภาระหนี้สินหากเวียชีวิตก่อนหมดภาระหนี้",
    weight: [1, 2],
  },
  {
    question:
      "9. เงินทุนเพื่อเป็นค่าใช้จ่ายของครอบครัวในระยะเวลา 5 ปี หากจากไปก่อนวัยอันควร",
    weight: [1, 2],
  },
  {
    question:
      "10. เงินทุนสำหนับเป้าหมายของผู้ที่อยู่ในอุปการะ เช่น ทุนการศึกษาบุตร",
    weight: [1, 2],
  },
];

export const riskAssessmentResults = [
  // Not Patron
  [
    {
      interval: 2,
      result:
        "คุณเป็นบุคคลที่มีความเสี่ยงส่วนบุคคลในระดับสูงมาก ถือว่าเกือบจะได้ได้เตรียมตัวรับมือหากเกิดเหตุไม่คาดฝันกับตัวคุณเลย การที่ต้องใช้ชีวิตภายใต้ความเสี่ยงคุณต้องการที่ปรึกษาอย่างเร่งด่วน ติดต่อนักวางแผนการเงิน คลิ๊ก!!!",
    },
    {
      interval: 5,
      result:
        "คุณเป็นบุคคลที่มีความเสี่ยงส่วนบุคคลในระดับสูง มีความเสี่ยงในอีกหลายๆด้านที่คุณยังไม่ได้วางแผนเตรียมรับมือ ถึงคุณจะไม่มีใครที่เป็นภาระคุณ แต่คุณต้องเตรียมตัวเพื่อไม่ให้เป็นภาระของใคร คุณสามารถวางแผนอื่นๆเพิ่มเติมได้ คลิ๊ก!!!",
    },
    {
      interval: 9,
      result:
        "คุณเป็นบุคคลที่มีความเสี่ยงส่วนบุคคลในระดับปานกลาง จากแผนจัดการความเสี่ยงที่ยังไม่ครบ คุณไม่มีใครต้องดูแล แต่อย่าลืมว่าอย่าให้คนอื่นต้องมาดูแล หากเกิดเหตุไม่คาดฝันกับคุณ ปรึกษานักวางแผนการเงิน คลิ๊ก!!!",
    },
    {
      interval: 13,
      result:
        "สำหรับคนที่ไม่มีภาระอย่างคุณ คุณเป็นบุคคลที่วางแผนรับมือกับความเสี่ยงในชีวิตได้อย่างดีเยี่ยม ชีวิตของคุณจึงมีแนวโน้มมั่นคง และปลอดภัย หากคิดว่ามีแผนที่ยังไม่ครบหรือสนใจเรื่องการลงทุน สามารถติดต่อนักวางแผนการเงินเพิ่มเติมได้",
    },
  ],
  //Patron
  [
    {
      interval: 2,
      result:
        "คุณเป็นบุคคลที่มีความเสี่ยงส่วนบุคคลในระดับสูงมาก ซึ่งหมายถึงความเสี่ยงที่จะกระทบกับครอบครัวของคุณก็มีมากขึ้นเช่นเดียวกัน ยังมีความเสี่ยงอีกหลายด้านที่คุณต้องรีบวางแผนรับมือ ปรึกษานักวางแผนการเงินด่วน คลิ๊ก!!!",
    },
    {
      interval: 5,
      result:
        "คุณเป็นบุคคลที่มีความเสี่ยงส่วนบุคคลในระดับสูง หากครอบครัวขาดคุณไป ภาระที่แบกไว้อาจตกไปที่ครอบครัวที่คุณรัก สร้างภูมิคุ้มกันให้คุณและครอบครัว ด้วยการวางแผนจัดการความเสี่ยงเพิ่มเติม คลิ๊ก!!!",
    },
    {
      interval: 9,
      result:
        "คุณเป็นบุคคลที่มีความเสี่ยงส่วนบุคคลในระดับปานกลาง ครอบครัวข้างหลังเป็นแรงผลักดันสำหรับชีวิตคุณ คุณสามารถปกป้องครอบครัวที่คุณรักได้ด้วยตัวคุณเอง ความเสี่ยงเหล่านี้สามารถวางแผนป้องกันได้ คลิ๊ก!!!",
    },
    {
      interval: 13,
      result:
        "ในฐานะเสาหลักของครอบครัว คุณสามารถวางแผนจัดการความเสี่ยงได้อย่างดีเยี่ยม ผลกระทบที่จะเกิดกับครอบครัวมีน้อยจากแผนการที่คุณเตรียมไว้ คุณสามารถรับคำปรึกษาเพิ่มเติมจากนักวางแผนการเงินได้",
    },
  ],
];

// Plans
export const defaultPlansName = [
  "แผนประกันสุขภาพ",
  "แผนประกันอุบัติเหตุ",
  "แผนประกันโรคร้ายแรง",
  "แผนชดเชยรายได้",
  "แผนคุ้มครองรายได้",
  "แผนคุ้มครองทรัพย์สิน",
  "แผนเกษียณ",
  "แผนคุ้มครองภาระหนี้",
  "แผนการปรับตัวของครอบครัว",
  "แผนการศึกษาบุตร",
];

export const initialData = {
  riskAssessment: {
    patron: -1,
    isPrepared: Array(riskAssessmentQuestions.length).fill(-1),
  },
};
