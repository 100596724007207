import {useState} from "react";
import {defaultPlansName} from "../../constants";
import {IPlan} from "../../types";

const usePlans = (initialPlans?: IPlan[]) => {
  const defaultPlans = defaultPlansName.map((name, idx) => ({
    id: idx,
    isDefault: true,
    name,
    selected: false,
    requiredMoney: 0,
    preparedMoney: 0,
  }));

  const [plans, setPlans] = useState<IPlan[]>([
    ...(initialPlans || defaultPlans),
  ]);

  const add = (name: string) => {
    plans.push({
      name,
      id: +new Date(),
      isDefault: false,
      selected: true,
      requiredMoney: 0,
      preparedMoney: 0,
    });
    setPlans([...plans]);
  };

  const get = (id: number) => {
    return plans.find((plan) => plan.id === id)!;
  };

  const getAll = () => plans;

  const remove = (id: number) => {
    const index = plans.findIndex((plan) => plan.id === id);
    if (plans[index].isDefault) {
      plans[index] = {...plans[index], selected: false};
    } else {
      plans.splice(index, 1);
    }
    setPlans([...plans]);
  };

  const update = (id: number, payload: Partial<IPlan>) => {
    const index = plans.findIndex((plan) => plan.id === id);
    plans[index] = {...plans[index], ...payload};
    setPlans([...plans]);
  };

  return {add, remove, update, get, getAll};
};

export default usePlans;
