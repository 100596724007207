import {
  defaultPlansName,
  riskAssessmentQuestions,
  riskAssessmentResults,
} from "../../../constants";
import {useFinanCheckData} from "pages/finanCheck/context/finanCheckContext";

const useRiskResult = () => {
  const {riskAssessment} = useFinanCheckData();

  const patron = riskAssessment.patron;
  const missingPlans: string[] = [];

  let score: number = 0;
  riskAssessment.isPrepared.forEach((ans, idx) => {
    score += ans * riskAssessmentQuestions[idx].weight[patron];
    if (!ans) missingPlans.push(defaultPlansName[idx]);
  });

  let description = riskAssessmentResults[patron].find(({interval}) => {
    return score <= interval;
  })?.result;

  return {score, description, missingPlans: missingPlans.join(", ")};
};

export default useRiskResult;
