import React, {useCallback, useEffect, useState} from "react";

const useNumberInput = (
  value: number,
  type: string,
  onChange: (value: number) => void
) => {
  // Add comma to string
  const addComma = useCallback((str: string | number) => {
    var parts = String(str).split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    while (parts[0].length > 1 && parts[0][0] == "0")
      parts[0] = parts[0].slice(1);
    return parts.join(".");
  }, []);

  const [text, setText] = useState(addComma(value));

  // Change text if value change
  useEffect(() => {
    if (value != Number(text.replaceAll(",", ""))) {
      setText(addComma(value));
    }
  }, [addComma, text, value]);

  // handle Change
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.replaceAll(",", "");

      // check if value is blank
      if (value === "") {
        onChange!(0);
        return;
      }

      const floatRegex = /^\d*[.]?\d*$/;
      const intRegex = /^\d+$/;
      // check if value is float or int
      if (
        (type === "float" && floatRegex.test(value)) ||
        (type === "int" && intRegex.test(value))
      ) {
        setText(addComma(value));
        onChange!(Number(value));
      }
    },
    [addComma, onChange, type]
  );

  return {handleChange, text};
};

export default useNumberInput;
