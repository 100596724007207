import {makeStyles} from "@material-ui/core";
import color from "config/color";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "200px",
    margin: "5px",
    backgroundColor: color.grey[100],
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    boxShadow: "2px 2px 2px #D3D3D3",
    padding: "10px 5px 10px 5px ",
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 10px 0",
      width: "100%",
      maxWidth: "calc(100vw - 30px)",
    },
  },
  title: {
    marginTop: "20px",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      wordBreak: "break-word",
      whiteSpace: "normal",
      minHeight: "65px",
    },
  },
  chart: {
    display: "flex",
    justifyContent: "center",
    flexShrink: 1,
    margin: "15px 0 15px 0",
  },
  data: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
  },
}));
