import {useCallback} from "react";
import {ITableData} from "../props";

const useDataTable = () => {
  const getDataTableByColumn = useCallback(
    ({data, tableKey, label, highlight}: ITableData) => {
      const dataTable: (string | number)[][] = [];
      const highlightTable: boolean[][] = [];

      tableKey.forEach((key) => {
        // Data
        const rowData: (string | number)[] = [label[key]];
        for (const row of data) {
          rowData.push(row[key]);
        }
        dataTable.push(rowData);

        // Highlight
        const rowHighlight: boolean[] = [false];
        if (highlight) {
          for (const row of highlight) {
            rowHighlight.push(row.includes(key));
          }
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          for (const _ of data) {
            rowHighlight.push(false);
          }
        }
        highlightTable.push(rowHighlight);
      });

      return {dataTable, highlightTable};
    },
    []
  );

  const tranpose = useCallback((data: any[][]) => {
    return data[0].map((_, c) => {
      return data.map((r) => r[c]);
    });
  }, []);

  const getDataTable = useCallback(
    (data: ITableData, type: "row" | "column") => {
      const {dataTable, highlightTable} = getDataTableByColumn(data);
      // Column
      if (type === "column") return {dataTable, highlightTable};
      // Row (Tranpose)
      else
        return {
          dataTable: tranpose(dataTable),
          highlightTable: tranpose(highlightTable),
        };
    },
    [getDataTableByColumn, tranpose]
  );

  return {getDataTable};
};

export default useDataTable;
