import {InputBase} from "@material-ui/core";
import withFormik from "common/hoc/withFormik";
import React, {useCallback} from "react";
import {useStyles} from "./styles";
import {TextInputProps} from "./types";

const TextInput = (props: TextInputProps) => {
  const styles = useStyles();
  const {value, onChange, field} = props;

  const handleClick = useCallback(
    (event: React.ChangeEvent<any>) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <InputBase
      id="other"
      multiline
      inputProps={{
        min: 0,
        style: {textAlign: "center"},
      }}
      onChange={handleClick}
      value={value}
      className={styles.input}
      {...field}
    />
  );
};
export default withFormik(TextInput);
