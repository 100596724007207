import {createMuiTheme, responsiveFontSizes} from "@material-ui/core";
import color from "./color";

declare module "@material-ui/core/styles/createBreakpoints" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

let theme = createMuiTheme({
  palette: {
    primary: {
      light: color.blue[300],
      main: color.blue[400],
      dark: color.blue[500],
      contrastText: "white",
    },
  },
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0, // small smartphone
      sm: 375, // smartphone
      md: 600, // tablet
      lg: 1024, // desktop
      xl: 1920, // large desktop
    },
  },
  typography: {
    fontFamily: "Mitr",
    h1: {
      fontSize: "2.5rem",
      fontWeight: 400,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 400,
    },
    h3: {
      fontSize: "1.625rem",
      fontWeight: 400,
    },
    body1: {
      fontSize: "1.25rem",
      fontWeight: 400,
    },
    body2: {
      fontSize: "1.00rem",
      fontWeight: 400,
    },
  },
});

theme = responsiveFontSizes(theme);
export default theme;
