import {RouteContext} from "common/context/routeContext";
import React from "react";
import {useContext} from "react";
import routes, {pageRoutes} from "routes";

enum Process {
  menu,
  form,
  result,
}

interface IUseRouter {
  setMenu: (menu: string) => void;
  setProcess: (process: number) => void;
  process: number;
  latestProcess: number;
  setPage: (page: string) => void;
}

function useRouter(): IUseRouter {
  const routeContext = useContext(RouteContext);

  const error = (): void => {
    routeContext.setProcess(0);
    routeContext.setLatestProcess(0);
    routeContext.setMenu("default");
  };

  const getPage = (page: string) => {
    return pageRoutes[routeContext.menu][page];
  };

  // Menu
  const setMenu = (menu: string): void => {
    if (routes[routeContext.menu as never]) {
      routeContext.setProcess(1);
      routeContext.setLatestProcess(1);
      routeContext.setMenu(menu);
      routeContext.setPage("default");
    } else {
      error();
    }
  };

  // Page

  const setPage = (page: string): void => {
    if (getPage(page)) {
      routeContext.setPage(page);
      routeContext.setProcess(Process[getPage(page).type]);
    } else {
      error();
    }
  };

  const setProcess = (process: number) => {
    if (Process[getPage(routeContext.page).type] !== process) {
      for (const page in pageRoutes[routeContext.menu]) {
        if (Process[getPage(page).type] === process) {
          setPage(page);
          break;
        }
      }
    }
    routeContext.setProcess(process);
  };

  return {
    setMenu,
    setProcess,
    process: routeContext.process,
    latestProcess: routeContext.latestProcess,
    setPage,
  };
}

const MenuRoutes = React.memo((): JSX.Element => {
  const routeContext = useContext(RouteContext);
  if (routeContext.process === 0) return React.createElement(routes["home"]);
  return React.createElement(routes[routeContext.menu as never]);
});

const PageRoutes = React.memo((): JSX.Element => {
  const routeContext = useContext(RouteContext);
  if (routeContext.process === 0) return <>Loading</>;
  return React.createElement(
    pageRoutes[routeContext.menu][routeContext.page].component
  );
});

export default useRouter;
export {MenuRoutes, PageRoutes};
