import {Typography} from "@material-ui/core";
import Button from "common/components/Button";
import FormContainer from "common/components/FormContainer";
import color from "config/color";
import {useField} from "formik";
import RpqQuestion from "local/retirementPlan/RpqQuestion";
import {useEffect, useState} from "react";
import Question from "./components/Question";

function RPQ(props: {closeDialog: () => void}) {
  const [, , helpers] = useField("risk_accepted");
  const [score, setScore] = useState<number | null>(null);
  const [riskLevel, setRiskLevel] = useState<number | null>(null);
  const [selected, setSelected] = useState<number[][]>(
    Array(RpqQuestion.length)
      .fill(0)
      .map(() => [])
  );
  useEffect(() => {
    let sum = 0;
    for (const arr of selected) {
      if (arr.length === 0) {
        setScore(null);
        setRiskLevel(null);
        return;
      }
      sum += Math.max(...arr) + 1;
    }
    setScore(sum);
    if (sum <= 14) {
      setRiskLevel(1);
    } else if (sum <= 21) {
      setRiskLevel(2);
    } else if (sum <= 29) {
      setRiskLevel(3);
    } else if (sum <= 36) {
      setRiskLevel(4);
    } else {
      setRiskLevel(5);
    }
  }, [selected]);
  return (
    <FormContainer
      name="แบบประเมินความเสี่ยงที่ยอมรับได้
      (Risk Profile Questionaire: RPQ)"
      style={{width: "95%"}}
      contentStyle={{
        backgroundColor: "white",
        overflow: "auto",
        maxHeight: "80vh",
      }}
    >
      <Typography variant="body1" style={{margin: "10px 0 10px 0"}}>
        คำถามข้อ 1 - 10 ใช้เพื่อประเมินความเหมาะสมในการลงทุนของท่าน
      </Typography>
      {RpqQuestion.map((data, idx) => (
        <Question
          key={idx}
          questionIdx={idx}
          question={`${idx + 1}. ${data.question}`}
          choices={data.choices}
          setSelected={setSelected}
          selected={selected}
        />
      ))}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
          flexWrap: "wrap",
        }}
      >
        {score && (
          <>
            <div style={{display: "flex"}}>
              <Typography>รวมคะแนน</Typography>
              <Typography
                style={{
                  backgroundColor: color.grey[200],
                  borderRadius: "10px",
                  padding: "0 10px 0 10px",
                  margin: "0 10px 0 10px",
                }}
              >
                {score}
              </Typography>
            </div>
            <div style={{display: "flex"}}>
              <Typography>ระดับความเสี่ยงที่ยอมรับได้</Typography>
              <Typography
                style={{
                  backgroundColor: color.grey[200],
                  borderRadius: "10px",
                  padding: "0 10px 0 10px",
                  margin: "0 10px 0 10px",
                }}
              >
                {riskLevel}
              </Typography>
            </div>
          </>
        )}
      </div>
      <Button
        onClick={() => {
          if (riskLevel) {
            helpers.setValue(String(riskLevel));
          }
          props.closeDialog();
        }}
        style={{display: "block", margin: "auto", marginTop: "20px"}}
      >
        กลับหน้ากรอกข้อมูล
      </Button>
    </FormContainer>
  );
}

export default RPQ;
