import {makeStyles} from "@material-ui/core";
import color from "config/color";

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: "20px 0 20px 0",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "20px 0 10px 0",
    },
  },
  item: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
      width: "0",
    },
  },
  cell: {
    flexGrow: 1,
    minHeight: "60px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: "1px solid white",

    [theme.breakpoints.down("sm")]: {
      width: "0",
    },
  },
  name: {
    backgroundColor: color.blue[300],
    borderRadius: "20px 20px 0 0",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "20px 0 0 20px",
    },
  },
}));
