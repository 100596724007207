import {Select} from "@material-ui/core";
import withFormik from "common/hoc/withFormik";
import React, {useCallback} from "react";
import {useStyles} from "./styles";
import {SelectInputProps} from "./types";

const SelectInput = (props: SelectInputProps) => {
  const styles = useStyles();
  const {options, value, onChange, field} = props;

  const handleClick = useCallback(
    (event: React.ChangeEvent<any>) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <Select
      native
      disableUnderline
      className={styles.input}
      {...field}
      value={value}
      onChange={handleClick}
    >
      {options.map((option) => (
        <option
          key={option.value}
          value={option.value}
          style={{color: "black", background: "white"}}
        >
          {option.text}
        </option>
      ))}
    </Select>
  );
};
export default withFormik(SelectInput);
