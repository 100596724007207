import {dataContext} from "common/context/context";
import useRouter from "hooks/useRouter";
import {ISumAssured} from "pages/sumAssured/types";
import {useCallback, useContext} from "react";
import {defaultValue} from "../../constants";

export const useForm = () => {
  const {data, setData} = useContext(dataContext);

  // get initial value
  const initialValues = data.sumAssured || defaultValue;

  // handle submit
  const {setPage} = useRouter();
  const handleSubmit = useCallback(
    (value: ISumAssured) => {
      setData({...data, sumAssured: value});
      setPage("result");
    },
    [data, setData, setPage]
  );

  return {initialValues, handleSubmit};
};
