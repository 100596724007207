import background from "local/sumAssured.png";
import {Hidden} from "@material-ui/core";
import React from "react";
import {PageRoutes} from "hooks/useRouter";
function SumAssured() {
  return (
    <>
      <Hidden smDown>
        <img
          src={background}
          alt=""
          style={{
            top: 0,
            right: 0,
            height: "100vh",
            zIndex: -1,
            position: "fixed",
          }}
        />
      </Hidden>
      <PageRoutes />
    </>
  );
}
export default SumAssured;
