import {Radio, Typography} from "@material-ui/core";
import React from "react";

const Question = (props: {
  question: string;
  choices: string[];
  selected: number[][];
  questionIdx: number;
  setSelected: (selected: number[][]) => void;
}) => {
  const {selected, setSelected, questionIdx} = props;
  return (
    <div>
      <Typography>{props.question}</Typography>
      <div style={{display: "flex", flexWrap: "wrap"}}>
        {props.choices.map((choice, idx) => (
          <div
            key={idx}
            style={{flexGrow: 1, display: "flex", alignItems: "center"}}
          >
            <Radio
              color="primary"
              checked={selected[questionIdx].includes(idx)}
              onClick={() => {
                if (selected[questionIdx].includes(idx)) {
                  selected[questionIdx].splice(
                    selected[questionIdx].indexOf(idx),
                    1
                  );
                } else {
                  selected[questionIdx].push(idx);
                }
                // selected[questionIdx] = [...selected[questionIdx]];
                setSelected([...selected]);
              }}
            />
            <Typography variant="body2">{choice}</Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Question;
