import {Grid, Typography, Box} from "@material-ui/core";
import React from "react";
import {FormItemProps} from "./props";

function FormItem(props: FormItemProps) {
  const {label, input, unit, xs} = props;
  return (
    <Grid item xs={xs}>
      <Typography style={{padding: "5px 0px 5px 0px"}}>{label}</Typography>
      <Box display="flex" alignItems="center">
        {input}
        {unit && <Typography style={{marginLeft: "10px"}}>{unit}</Typography>}
      </Box>
    </Grid>
  );
}
export default FormItem;
