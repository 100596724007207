import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  column: {
    minWidth: "100%",
    textAlign: "center",
    alignItems: "center",
    minHeight: "40px",
    height: "100%",
    borderRadius: "10px",
    display: "flex",
    alignContent: "center",
    overflow: "hidden",
    background: "white",
  },
  typo: {
    flexGrow: 1,
    paddingLeft: "10px",
    paddingRight: "10px",
    wordWrap: "break-word",
    maxWidth: "calc(100% - 20px)",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5px",
      paddingRight: "5px",
      maxWidth: "calc(100% - 10px)",
    },
  },
}));
