import {Typography} from "@material-ui/core";
import React, {useState} from "react";
import Table from "common/components/table";
import TypeInput from "./components/TypeInput";
import {cellStyle, useStyles} from "./styles";
import Loading from "common/components/Loading";
import {useResult} from "./hooks/useResult";

function SumAssuredResult() {
  const styles = useStyles();
  const [type, setType] = useState(false);

  const {tableData, loading, base} = useResult(type);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      <Typography variant="h1" color="primary">
        ทุนประกันชีวิตที่เหมาะสม
      </Typography>
      <div className={styles.summaryContainer}>
        <TypeInput type={type} setType={setType} />
        <div className={styles.sum}>
          <Typography variant="h2" style={{flexGrow: 1}}>
            {base.toLocaleString()} บาท
          </Typography>
        </div>
      </div>
      <Table {...tableData} cellStyles={cellStyle} />
    </div>
  );
}
export default SumAssuredResult;
