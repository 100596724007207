import {Grid, Typography} from "@material-ui/core";
import React from "react";
import {useStyles} from "./styles";
import {TableTypographyProps} from "./types";

function TableTypo(props: TableTypographyProps) {
  const {md, xs, style, children} = props;
  const styles = useStyles();
  return (
    <Grid item md={md} xs={xs} style={style}>
      <div className={styles.column}>
        <Typography className={styles.typo}>{children}</Typography>
      </div>
    </Grid>
  );
}
export default TableTypo;
