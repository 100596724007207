import axios from "axios";
import {dataContext} from "common/context/context";
import {useContext, useEffect, useState} from "react";
import {v4} from "uuid";

export const useResult = (type: boolean) => {
  const [planList, setPlanList] = useState([]);
  const {data} = useContext(dataContext);
  const sumAssuredData = data.sumAssured!;

  const calculateBase = () => {
    const rate = sumAssuredData.inflaRate / 100;
    let needbase = sumAssuredData.outcome * 12;
    let potentialbase = sumAssuredData.income * 12;
    for (let i = 1; i < sumAssuredData.adaptPeriod; i++) {
      needbase += (sumAssuredData.outcome * 12) / Math.pow(1 - rate, i);
    }
    needbase = needbase + sumAssuredData.debt;
    needbase = Math.round(needbase / 1000) * 1000;
    for (let i = sumAssuredData.age; i < 60; i++) {
      potentialbase =
        potentialbase +
        Math.trunc(potentialbase * rate) +
        sumAssuredData.income * 12;
    }
    potentialbase = Math.round(potentialbase / 1000) * 1000;
    return {potentialbase, needbase};
  };

  const {potentialbase, needbase} = calculateBase();

  // TODO refactor this???
  let device_id = localStorage.getItem("device_id");
  if (device_id === null) {
    device_id = v4();
    localStorage.setItem("device_id", device_id);
  }

  const getResult = async () => {
    const url = "https://fhc.insurtechthailand.com/ins/sumassured/cal";
    const info = {
      cal_type: "sumassured",
      device_id,
      age: sumAssuredData.age,
      sex: sumAssuredData.gender,
      income: sumAssuredData.income,
      outcome: sumAssuredData.outcome,
      debt: sumAssuredData.debt,
      adoption_period: sumAssuredData.adaptPeriod,
      inflationrate: sumAssuredData.inflaRate,
      Base: [needbase, potentialbase],
      time: Date.now(),
      calculateAT: new Date().toLocaleString(),
    };
    // TODO use axois instance & handle error
    const response = await axios.post(url, info);
    setPlanList(response.data.data);
  };

  useEffect(() => {
    getResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const planData = planList.map((planData: any, inx) => {
    const mult = sumAssuredData.gender == 'female' ? planData.female : planData.male;
    const base = type ? potentialbase : needbase;
    return {
      plan: planData.plan,
      insurancePremiumPerYear: (mult * base) / 1000,
      year: planData.pay_period,
      insurancePremium: ((mult * base) / 1000) * planData.pay_period,
    };
  });

  // get highlight
  const getHighlight = () => {
    const minValue = [
      Math.min(...planData.map((d) => d.insurancePremiumPerYear)),
      Math.min(...planData.map((d) => d.year)),
      Math.min(...planData.map((d) => d.insurancePremium)),
    ];

    return planData.map((data) => {
      const rowHighlight: string[] = [];
      if (data.insurancePremiumPerYear == minValue[0]) {
        rowHighlight.push("insurancePremiumPerYear");
      }
      if (data.year == minValue[1]) {
        rowHighlight.push("year");
      }
      if (data.insurancePremium == minValue[2]) {
        rowHighlight.push("insurancePremium");
      }
      return rowHighlight;
    });
  };

  return {
    loading: planList.length == 0,
    base: type ? potentialbase : needbase,
    tableData: {
      tableKey: ["plan", "insurancePremiumPerYear", "year", "insurancePremium"],
      data: planData,
      label: {
        plan: "",
        insurancePremiumPerYear: "เบี้ยประกันภัย (ต่อปี)",
        year: "จำนวนปีที่ต้องชำระเงิน",
        insurancePremium: "เบี้ยประกันภัยรวม",
      },
      highlight: getHighlight(),
    },
  };
};
