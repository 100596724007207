import {Typography} from "@material-ui/core";
import Button from "common/components/Button";
import useRouter from "hooks/useRouter";
import React from "react";
import useRiskResult from "./hooks/useRiskResult";
import {useStyles} from "./styles";

const RiskAssessmentResult = () => {
  const classes = useStyles();
  const {setPage} = useRouter();
  const {score, description, missingPlans} = useRiskResult();

  return (
    <div>
      <div className={classes.scoreContainer}>
        <div className={classes.score}>
          <Typography variant="h1">{score}</Typography>
        </div>
        <Typography variant="h2">คะแนนความเสี่ยงของคุณ</Typography>
      </div>
      <Typography variant="body1">{description}</Typography>
      <Typography variant="body2" style={{marginTop: "20px"}}>
        {missingPlans !== "" && <>แผนที่ขาด: {missingPlans}</>}
      </Typography>
      <Button
        className={classes.button}
        onClick={() => setPage("requiredMoney")}
      >
        ต่อไป
      </Button>
    </div>
  );
};

export default RiskAssessmentResult;
