import {Box, Hidden, Typography} from "@material-ui/core";
import {IPlanList} from "pages/healthCare/types";
import React from "react";
import RadarChart from "react-svg-radar-chart";
import PlanButton from "./components/PlanButton";
import useResultData from "./hooks/useResultData";
import {useStyles} from "./styles";
import {captions, chartOptions} from "./constants";

interface Props {
  planList: IPlanList;
}

function ResultChart({planList}: Props) {
  const styles = useStyles();
  const {data, setType, type} = useResultData(planList);

  return (
    <div className={styles.container}>
      <PlanButton
        planName={[planList.data[0].plan, planList.data[1].plan]}
        setType={setType}
        type={type}
      />
      <Box display="flex" justifyContent="center" flexGrow="1">
        <Box position="relative" display="inline-block" justifyContent="center">
          <Hidden xsDown>
            <RadarChart
              captions={captions}
              data={data}
              size={500}
              options={chartOptions(styles)}
            />
          </Hidden>
          <Hidden smUp>
            <RadarChart
              captions={captions}
              data={data}
              size={300}
              options={chartOptions(styles)}
            />
          </Hidden>

          {/* Label */}
          <Typography variant="h3" className={styles.roomCost}>
            ค่าห้องพัก
          </Typography>
          <Typography variant="h3" className={styles.seriousIllnessCost}>
            ค่ารักษาพยาบาล
            <br />
            แบบเหมาจ่าย
          </Typography>
          <Typography variant="h3" className={styles.packageCost}>
            ค่ารักษา
            <br />
            โรคร้ายแรง
          </Typography>
        </Box>
      </Box>
    </div>
  );
}
export default ResultChart;
