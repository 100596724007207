import {makeStyles} from "@material-ui/core";
import color from "config/color";

export const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    borderRadius: "20px",
    backgroundColor: color.grey[100],
    padding: "10px",
    flexWrap: "wrap",
    margin: "10px 0 10px 0",
  },
  inputLabel: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
  },
}));
