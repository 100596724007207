import {makeStyles} from "@material-ui/core";
import color from "config/color";

export const useStyles = makeStyles((theme) => ({
  formContainer: {
    maxWidth: "900px",
    margin: "auto",
    marginTop: "20px",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  formHead: {
    backgroundColor: color.blue[400],
    padding: "10px",
    color: "white",
    fontSize: "1.5rem",
    textAlign: "center",
    borderRadius: "20px 20px 0 0 ",
  },
  formContent: {
    backgroundColor: color.grey[100],
    padding: "20px",
    borderRadius: "0 0 20px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
}));
