import ResultItem from "./components/ResultItem";
import {Grid} from "@material-ui/core";
import React from "react";
import FormContainer from "common/components/FormContainer";
import TableHead from "./components/TableHead";
import {useFinanCheckData} from "../../context/finanCheckContext";

const FinanCheckResult = () => {
  const {plans} = useFinanCheckData();

  const resultList = plans
    .getAll()
    .filter((plan) => plan.selected)
    .map((plan) => {
      return (
        <ResultItem
          key={plan.id}
          name={plan.name}
          prepareMoney={plan.preparedMoney}
          useMoney={plan.requiredMoney}
        />
      );
    });

  return (
    <FormContainer name="การวางแผนการเงิน">
      <Grid container spacing={1} style={{textAlign: "center"}}>
        <TableHead />
        {resultList}
      </Grid>
    </FormContainer>
  );
};
export default FinanCheckResult;
