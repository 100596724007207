import {dataContext} from "common/context/context";
import {createContext, useContext, useState} from "react";
import {defaultData} from "../constants";
import {IRetirementPlanContext} from "./types";

export const retireplanContext = createContext({} as IRetirementPlanContext);

export const useRetirementPlanContext = () => useContext(retireplanContext);

export const RetirementPlanProvider = (props: React.PropsWithChildren<{}>) => {
  const {data} = useContext(dataContext);
  const [temp, setTemp] = useState(
    !!data.retirePlan ? data.retirePlan : defaultData
  );

  return (
    <retireplanContext.Provider value={{temp, setTemp}}>
      {props.children}
    </retireplanContext.Provider>
  );
};
