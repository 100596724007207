import {Drawer, Theme, makeStyles} from "@material-ui/core";
import color from "config/color";
import React from "react";
import DrawerItem from "./DrawerItem";
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: color.blue[500],
  },
}));
function MenuDrawer(props: {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
}) {
  const {isOpen, setOpen} = props;
  const styles = useStyles();
  return (
    <>
      <Drawer
        anchor={"left"}
        classes={{paper: styles.container}}
        open={isOpen}
        onClose={() => setOpen(false)}
      >
        <DrawerItem id={-1} setOpen={setOpen} />
        <DrawerItem id={0} setOpen={setOpen} />
        <DrawerItem id={1} setOpen={setOpen} />
        <DrawerItem id={2} setOpen={setOpen} />
        <DrawerItem id={3} setOpen={setOpen} />
        <DrawerItem id={4} setOpen={setOpen} />
      </Drawer>
    </>
  );
}
export default MenuDrawer;
