import {dataContext} from "common/context/context";
import useRouter from "hooks/useRouter";
import {defaultValues} from "pages/healthCare/constants";
import {IHealthCare} from "pages/healthCare/types";
import {useCallback, useContext} from "react";

const useFormData = () => {
  const {setPage} = useRouter();
  const {data, setData} = useContext(dataContext);

  const initialValues = data.healthCare || defaultValues;

  const handleSubmit = useCallback(
    (value: IHealthCare) => {
      setData({...data, healthCare: value});
      setPage("result");
    },
    [data, setData, setPage]
  );

  return {initialValues, handleSubmit};
};

export default useFormData;
