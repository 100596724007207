import {Grid, Typography, Box} from "@material-ui/core";
import React from "react";
import {FormItemProps} from "./types";

function FormItem(props: FormItemProps) {
  const {label, input, unit, variant} = props;

  return (
    <>
      <Grid
        item
        xs={variant === "small" ? 2 : 6}
        md={variant === "small" ? 1 : 6}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography>{label}</Typography>
      </Grid>

      <Grid
        item
        xs={variant === "small" ? 10 : 6}
        md={variant === "small" ? 5 : 6}
      >
        <Box display="flex" alignItems="center">
          {input}
          {unit && <Typography style={{marginLeft: "10px"}}>{unit}</Typography>}
        </Box>
      </Grid>
    </>
  );
}
export default FormItem;
