import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  input: {
    backgroundColor: "white",
    width: "100%",
    height: "40px",
    padding: "10px",
    borderRadius: "10px",
    "& select": {
      "&:focus": {
        backgroundColor: "white",
      },
    },
  },
}));
