import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Button from "common/components/Button";
import useRouter from "hooks/useRouter";
import React from "react";
import Insurance from "./components/Insurance";
import MutualFund from "./components/MutualFund";
import useResult from "./hooks/useResult";
import {useStyles} from "./styles";

function Result() {
  const {setPage} = useRouter();
  const styles = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const {fetchData} = useResult();

  // Summary
  const SummaryItem = (props: {text: string; value: string | number}) => (
    <div style={{flexGrow: 1}}>
      <Typography variant="h3" style={{textAlign: "center", margin: "10px"}}>
        {props.text}
      </Typography>
      <div className={styles.summaryValue}>
        <Typography variant="body1" style={{flexGrow: 1, textAlign: "center"}}>
          {props.value.toLocaleString()}
        </Typography>
        <Typography variant="body1">บาท</Typography>
      </div>
    </div>
  );

  const Summary = () => (
    <div className={styles.summaryContainer}>
      <SummaryItem
        text="คุณต้องออมเงินเดือนละ"
        value={fetchData.need_savings}
      />
      <SummaryItem
        text="เพื่อให้มีเงินใช้หลังเกษียณ"
        value={fetchData.retryment_rq}
      />
    </div>
  );

  // Savings plan
  const SavingsPlan = () => (
    <div>
      <div style={{display: "flex", justifyContent: "center", margin: "20px"}}>
        <Typography variant="h2" className={styles.savingPlanTitle}>
          คำแนะนำการวางแผนออมเงิน
        </Typography>
      </div>
      <div style={{display: "flex", flexDirection: isMd ? "column" : "row"}}>
        <Insurance />
        <Divider
          orientation={isMd ? "horizontal" : "vertical"}
          flexItem={!isMd}
        />
        <MutualFund />
      </div>
    </div>
  );

  return (
    <Box marginTop="20px">
      <Typography variant="h1" color="primary">
        วางแผนเกษียณ
      </Typography>
      <Summary />
      <SavingsPlan />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexWrap: "wrap",
        }}
      >
        <Button onClick={() => setPage("insurance")}>ประกันชีวิต</Button>
        <Button onClick={() => setPage("mutualFund")}>กองทุนรวม</Button>
      </div>
    </Box>
  );
}

export default Result;
