import {ClassNameMap} from "@material-ui/styles";

// Get cell's className
const useCellClassName = (
  index: {
    row: number;
    column: number;
    maxRow: number;
    maxColumn: number;
  },
  styles: ClassNameMap
): string => {
  const {row, column, maxRow, maxColumn} = index;

  // Get list of class from index
  const getClasses = () => {
    const classes = ["cellContainer"];

    // First Row
    if (row === 0) {
      if (column === 1) classes.push("topLeft");
      else if (column === maxColumn - 1) classes.push("topRight");
    }

    // Second Row
    if (row === 1 && column === 0) classes.push("topLeft");

    // Last Row
    if (row === maxRow - 1) {
      if (column === 0) classes.push("bottomLeft");
      if (column === 1) classes.push("smBottomLeft");
      else if (column === maxColumn - 1) classes.push("bottomRight");
    }

    // First Column
    if (column === 0) classes.push("rowHead");

    return classes;
  };

  // Get class name from classes list
  const getClassName = (classes: string[]) => {
    let className = "";
    classes.forEach((name) => {
      className += `${styles[name]} `;
    });
    return className;
  };

  return getClassName(getClasses());
};

export default useCellClassName;
