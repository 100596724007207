import {Box, Grid, Hidden, Typography} from "@material-ui/core";
import React from "react";

const TableHead = () => {
  return (
    <>
      <Grid item md={6} xs={12}>
        <Typography variant="body1">ประเภทแผน</Typography>
      </Grid>
      <Hidden mdUp>
        <Grid item xs={12}>
          <Box bgcolor="black" height="1px" />
        </Grid>
      </Hidden>
      <Grid item md={2} xs={4}>
        <Typography variant="body1">จำนวนเงินที่ต้องเตรียม</Typography>
      </Grid>
      <Grid item md={2} xs={4}>
        <Typography variant="body1">เตรียมไว้แล้ว</Typography>
      </Grid>
      <Grid item md={2} xs={4}>
        <Typography variant="body1">ขาดอีก</Typography>
      </Grid>
    </>
  );
};

export default TableHead;
