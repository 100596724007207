import useRouter from "hooks/useRouter";
import {useStyles} from "./styles";
import {CardProps} from "./types";

function Card({menu}: CardProps) {
  const styles = useStyles();
  const image = require("local/home/" + menu + ".png").default;
  const {setMenu} = useRouter();

  const HandleClick = () => {
    setMenu(menu);
  };

  return (
    <img
      src={image}
      onClick={HandleClick}
      className={styles.container}
      alt=""
    />
  );
}
export default Card;
