import {Box, Grid, Typography} from "@material-ui/core";
import React from "react";
import PlanInput from "../../components/PlanInput";
import FormContainer from "common/components/FormContainer";
import useRouter from "hooks/useRouter";
import Button from "common/components/Button";
import {useFinanCheckData} from "../../context/finanCheckContext";
import AddButton from "common/components/AddButton";
import useModal from "hooks/useModal";
import SelectPlanDialog from "./components/SelectPlanDialog";
import {IPlan} from "../../types";

const RequiredMoney = () => {
  const {setPage} = useRouter();
  const {plans, saveData} = useFinanCheckData();

  const modal = useModal();

  const submit = () => {
    saveData();
    setPage("preparedMoney");
  };

  const selectedPlanList = plans
    .getAll()
    .filter((plan) => plan.selected)
    .map((plan: IPlan) => {
      return <PlanInput key={plan.id} plan={plan} inputKey="requiredMoney" />;
    });

  return (
    <>
      <SelectPlanDialog open={modal.open} onClose={modal.handleClose} />

      <FormContainer name="การวางแผนการเงิน">
        <Grid container spacing={1} style={{textAlign: "center"}}>
          <Grid item xs={6}>
            <Typography variant="body1">ประเภทแผน</Typography>
          </Grid>
          <Grid item xs={6} md={5}>
            <Typography variant="body1">จำนวนเงินที่ต้องเตรียม</Typography>
          </Grid>
          {selectedPlanList}
        </Grid>
        <AddButton onClick={modal.handleOpen} style={{marginTop: "10px"}}>
          เพิ่มแผน
        </AddButton>
      </FormContainer>

      <Box maxWidth="900px" margin="auto">
        <Button onClick={submit} style={{float: "right", marginTop: "10px"}}>
          ต่อไป
        </Button>
      </Box>
    </>
  );
};

export default RequiredMoney;
