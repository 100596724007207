import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  button: {
    width: "250px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      justifyContent: "center",
    },
  },
}));
