import axios from "axios";
import {dataContext} from "common/context/context";
import useRouter from "hooks/useRouter";
import {useRetirementPlanContext} from "pages/retirementPlan/context/retirementPlanContext";
import {IFundRetirePort} from "pages/retirementPlan/types";
import {getAssetType} from "pages/retirementPlan/utils/fetch";
import {useContext, useEffect, useState} from "react";

const useMutualFund = () => {
  const {setPage} = useRouter();
  const {temp, setTemp} = useRetirementPlanContext();
  const {data, setData} = useContext(dataContext);
  const sampleDict: {[key: string]: string} = {};
  const [assetClassDict, setAssetClassDict] = useState(sampleDict);

  const [recPorts, setRecPorts] = useState([]);
  const [selected, setSelected] = useState(-1);

  const getResult = async () => {
    let device_id = localStorage.getItem("device_id");
    const url = "https://fhc.insurtechthailand.com/ins/retirement/cal";
    if (!!temp) {
      const {
        age,
        gender,
        retriment_age,
        death_age,
        outcome,
        inflationrate,
        retryment_rq,
        risk_data,
        need_insurance,
        risk_accepted,
      } = temp;
      const info = {
        device_id: device_id, // device_id ของ local
        cal_type: "fund_recommend", // function ที่เรียกใช้   ins_recommend , fund_recommend
        sex: gender, //เพศ
        age, //อายุปัจจุบัน
        retriment_age, // defualt 60
        death_age, // defualt 85
        outcome, //รายจ่ายปัจจุบัน
        inflationrate, //อัตราเงินเฟ้อ
        retryment_sh:
          outcome * 0.7 * (1 + inflationrate) ** (retriment_age - age), //เงินบำนาญขั้นต่ำที่ควรมี   =  outcome * 0.7 ((1+(inflation))**(retriment_age - Age))
        retryment_rq, //เงินบำนาญที่ต้องการรมี  ระบุเอง
        risk_form_id: risk_data.risk_form_id, //not edit  default จาก response.risk_form_id
        risk_assessed: risk_data.risk_assessed, //not edit  default จาก response.risk_assessed
        risk_accepted, //ความเสี่ยงจากการลงทุนที่รับได้ default จาก response.risk_assessed   แต่เลือกเปลี่ยนเองได้
        need_insurance: need_insurance, //  0 - 100 need_insurance + need_mutualfund =  100
        need_mutualfund: 100 - need_insurance, //  0 - 100 need_insurance + need_mutualfund =  100
        time: Date.now(),
        calculateAT: new Date().toLocaleString(),
      };
      const response = await axios.post(url, info);
      if (!!response.data.fund_ports) {
        setRecPorts(response.data.fund_ports);
      }
    }
    setAssetClassDict(await getAssetType());
  };

  useEffect(() => {
    getResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Page Change
  const handlePageChange = (page: string) => () => {
    let selectPlan: IFundRetirePort = temp.fund_port;
    if (selected != -1) {
      selectPlan = recPorts[selected];
    }
    setTemp({...temp, fund_port: selectPlan});
    setData({...data, retirePlan: {...temp, fund_port: selectPlan}});
    setPage(page);
  };

  // Ports Data
  const portsData = recPorts.map((port: IFundRetirePort, index: number) => {
    const percents: {[key: number]: number} = {};
    let allweight = 0;
    port.lists.forEach((asset) => {
      asset.a.forEach((percent) => {
        const aType: number = parseFloat(Object.keys(percent)[0]);
        const aPercent = percent[aType];
        if (!percents[aType]) {
          percents[aType] = 0;
        }
        percents[aType] += aPercent * asset.w;
      });
      allweight += asset.w;
    });
    const percentList: {name: string; percent: number}[] = [];
    for (const [key, value] of Object.entries(percents)) {
      percentList.push({
        name: assetClassDict[key],
        percent: value / allweight,
      });
    }
    return {
      risk: port.name,
      percent: percentList,
      expectedReturn: port.stat.expected,
      SD: port.stat.sd,
      recommend: index + 1 === temp.risk_accepted,
      selected: selected === index,
      key: index,
      port_id: port.port_id,
      onClick: () => setSelected(index),
    };
  });

  return {portsData, handlePageChange};
};

export default useMutualFund;
