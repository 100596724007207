import axios from "axios";

export const getAssetType = async () => {
  const asset = sessionStorage.getItem("asset");
  if (!!asset) {
    return JSON.parse(asset);
  }
  let device_id = localStorage.getItem("device_id");
  const url = "https://fhc.insurtechthailand.com/ins/fund/data";
  const info = {
    device_id: device_id, // device_id ของ local
    cal_type: "assetclass_list", // function ที่เรียกใช้
  };
  const response = await axios.post(url, info);
  if (!!response.data.data) {
    const sampleDict: {[key: string]: string} = {};
    response.data.data.forEach((element: {[key: string]: string}) => {
      const id = Object.keys(element)[0];
      sampleDict[id] = element[id];
    });
    sessionStorage.setItem("asset", JSON.stringify(sampleDict));
    return sampleDict;
  }
  return {};
};
