import {Grid, Hidden, Typography} from "@material-ui/core";
import React from "react";

const QuestionHead = () => {
  return (
    <Grid container style={{marginTop: "30px", marginBottom: "40px"}}>
      <Grid item xs={12} md={8}>
        <Typography variant="body1">
          ท่านได้มีการวางแผนรับมือกับความเสี่ยงเหล่านี้ด้านใดบ้าง
        </Typography>
      </Grid>
      <Hidden smDown>
        <Grid item xs={2}>
          <Typography
            variant="body1"
            style={{
              textAlign: "center",
            }}
          >
            เตรียมพร้อมแล้ว
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            variant="body1"
            style={{
              textAlign: "center",
            }}
          >
            ยังไม่เตรียม
          </Typography>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default QuestionHead;
