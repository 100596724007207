import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  sliderLabel: {
    maxWidth: "100%",
    fontSize: "1.25rem",
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      flexShrink: 1,
    },
  },
  sliderContainer: {
    display: "flex",
    flexGrow: 1,
    width: "100%",
    flexWrap: "wrap",
    marginTop: "15px",
  },
}));
