import {Typography} from "@material-ui/core";
import React from "react";
import {CellProps} from "./props";
import {useStyles} from "./styles";
import useCellClassName from "./hooks/useCellClassName";
import useCellStyle from "./hooks/useCellStyle";

const Cell = ({children, cellStyle, highlight, index}: CellProps) => {
  const styles = useStyles();
  const className = useCellClassName(index, styles);
  const style = useCellStyle(index, cellStyle);

  if (index.row === 0 && index.column === 0)
    return <div className={styles.blank} />;

  return (
    <div className={className} style={style}>
      <div className={`${styles.cell} ${highlight ? styles.highlight : ""}`}>
        <Typography variant="body1">
          {(children === null || children === undefined
            ? ""
            : children
          ).toLocaleString()}
        </Typography>
      </div>
    </div>
  );
};

export default Cell;
