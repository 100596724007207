import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  button: {
    width: "250px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
