import {Grid, Typography} from "@material-ui/core";
import React from "react";
import FormContainer from "common/components/FormContainer";
import PlanInput from "../../components/PlanInput";
import useRouter from "hooks/useRouter";
import Button from "common/components/Button";
import {useFinanCheckData} from "../../context/finanCheckContext";

const PreparedMoney = () => {
  const {plans, saveData} = useFinanCheckData();
  const {setPage} = useRouter();

  const submit = () => {
    saveData();
    setPage("result");
  };

  const showList = plans
    .getAll()
    .filter((plan) => plan.selected)
    .map((plan) => {
      return <PlanInput key={plan.id} plan={plan} inputKey="preparedMoney" />;
    });

  const SubmitButton = () => (
    <Button
      onClick={submit}
      center
      style={{
        marginTop: "10px",
      }}
    >
      สรุปผล
    </Button>
  );

  return (
    <>
      <FormContainer name="การวางแผนการเงิน">
        <Grid
          container
          spacing={1}
          style={{marginBottom: "5px", textAlign: "center"}}
        >
          <Grid item xs={6}>
            <Typography variant="body1">ประเภทแผน</Typography>
          </Grid>
          <Grid item md={5} xs={6}>
            <Typography variant="body1">จำนวนเงินที่เตรียมไว้แล้ว</Typography>
          </Grid>
          {showList}
        </Grid>
      </FormContainer>

      <SubmitButton />
    </>
  );
};

export default PreparedMoney;
