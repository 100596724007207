import {Box, IconButton, Typography} from "@material-ui/core";
import React from "react";
import AddIcon from "@material-ui/icons/Add";
import {AddButtonProps} from "./types";
import color from "config/color";

const AddButton = (props: AddButtonProps) => {
  const {children, onClick, select, style} = props;

  return (
    <Box display="flex" alignItems="center" style={style}>
      <IconButton
        aria-label="add"
        onClick={onClick}
        color="primary"
        style={{background: select ? color.blue[100] : "white"}}
      >
        <AddIcon />
      </IconButton>
      {children && (
        <Typography style={{marginLeft: "10px"}}>{children}</Typography>
      )}
    </Box>
  );
};

export default AddButton;
