import React, {useCallback, useState} from "react";
import Result1 from "./components/ResultTable";
import Result2 from "./components/ResultChart";
import {Box, Typography} from "@material-ui/core";
import {useResult} from "./hooks/useResult";
import Button from "common/components/Button";
import {useStyles} from "./styles";
import Loading from "common/components/Loading";

function Result() {
  const [type, setType] = useState<"table" | "chart">("table");
  const toggleType = useCallback(() => {
    setType((type) => (type === "table" ? "chart" : "table"));
  }, []);

  const planList = useResult();
  const styles = useStyles();

  if (!planList) return <Loading />;
  return (
    <div className={styles.container}>
      <Typography variant="h1" color="primary">
        ประกันสุขภาพระยะยาว
      </Typography>
      <div className={styles.content}>
        {type === "table" ? (
          <Result1 planList={planList!} />
        ) : (
          <Result2 planList={planList!} />
        )}
      </div>

      {/* Remark */}
      <Box display="flex" justifyContent="flex-end" marginTop="20px">
        <Typography variant="body1">{planList.data[0].remark}</Typography>
        <Typography variant="body1">{planList.data[1].remark}</Typography>
      </Box>

      {/* Toggle Type */}
      <Button
        style={{
          float: "right",
          marginTop: "15px",
        }}
        onClick={toggleType}
      >
        {type === "table" ? "แผนภูมิ" : "ตาราง"}
      </Button>
    </div>
  );
}
export default Result;
