import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  RPQ: {
    fontSize: "1.25rem",
    width: "200px",
    flexGrow: 1,
    margin: "auto",
    maxWidth: "350px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      maxWidth: "300px",
    },
  },
  ageInput: {
    margin: "10px 0 10px 0",
    width: "100%",
    display: "flex",
  },
}));
