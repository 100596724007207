import color from "config/color";

const getTypeColor = (type: string) => {
  const typeColor = {
    ตลาดเงิน: "#C296D2",
    ตราสารหนี้: color.blue[200],
    ราสารหนี้: color.blue[200],
    หุ้นไทย: color.blue[400],
    "ทรัพย์สิน อื่นๆ": color.grey[400],
    ตราสารหนี้ต่างประเทศ: "#6BCE93",
    หุ้นต่างประเทศ: "#449693",
    ทอง: "#E2C961",
    อสังหาและโครงสร้างพื้นฐาน: "#E0997B",
  };

  return (typeColor as any)[type] || color.blue[100];
};
export default getTypeColor;
