import {makeStyles} from "@material-ui/core";
import color from "config/color";

export const useStyles = makeStyles((theme) => ({
  scale: {
    fill: color.grey[100],
    stroke: color.grey[200],
  },
  axis: {
    stroke: "black",
    strokeWidth: ".8",
  },
  roomCost: {
    position: "absolute",
    textAlign: "center",
    width: "100%",
    top: 0,
  },
  seriousIllnessCost: {
    position: "absolute",
    left: "5%",
    bottom: "15%",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      left: "0",
    },
  },
  packageCost: {
    position: "absolute",
    right: "5%",
    bottom: "15%",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      right: "0",
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      flexDirection: "column-reverse",
    },
  },
}));
