import {makeStyles} from "@material-ui/core";
import color from "config/color";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "900px",
    margin: "auto",
    marginTop: "20px",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  summaryContainer: {
    marginTop: "20px",
    width: "60%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  sum: {
    backgroundColor: color.grey[100],
    display: "flex",
    minHeight: "60px",
    alignItems: "center",
    textAlign: "center",
    borderRadius: "10px",
    marginTop: "10px",
    marginBottom: "20px",
  },
  tableHead: {
    backgroundColor: color.blue[400],
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    minHeight: "60px",
    color: "white",
    border: "1px solid white",
  },
}));

export const cellStyle = {
  headStyle: {backgroundColor: color.blue[400], color: "white"},
  rowHeadStyle: {
    backgroundColor: color.blue[300],
    borderRadius: "20px 0 0 20px",
  },
};
