import React from "react";

import {PageRoutes} from "hooks/useRouter";
import {FinanCheckProvider} from "./context/finanCheckContext";

const FinanCheck = () => {
  return (
    <FinanCheckProvider>
      <PageRoutes />
    </FinanCheckProvider>
  );
};

export default FinanCheck;
