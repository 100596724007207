import color from "config/color";

export const cellStyles = {
  headStyle: {backgroundColor: color.blue[300], color: "white"},
  rowHeadStyle: {
    backgroundColor: color.blue[400],
    color: "white",
    borderRadius: "20px 0 0 20px",
  },
};
