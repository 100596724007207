import {makeStyles, Theme} from "@material-ui/core";
import React from "react";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import color from "config/color";
import useRouter from "hooks/useRouter";
interface StyleProps {
  filter: string;
  cursor: string;
  background: string;
}
const useStyles = makeStyles((theme: Theme) => ({
  img: {
    width: "50px",
    padding: "5px",
    color: "white",
    filter: (props: StyleProps) => props.filter,
    cursor: (props: StyleProps) => props.cursor,
    fontSize: "3rem",
    background: (props: StyleProps) =>
      props.background === "light" ? color.blue[400] : "",
  },
}));
function SidebarItem(props: {id: number; setOpen: (open: boolean) => void}) {
  const {id, setOpen} = props;
  const {setProcess, process, latestProcess} = useRouter();

  const styles = useStyles({
    background: id === process ? "light" : "",
    filter:
      id > latestProcess && id < 3
        ? "invert(42%) sepia(6%) saturate(179%) hue-rotate(177deg) brightness(84%) contrast(86%)"
        : "",
    cursor: (id != -1 && id <= latestProcess) || id >= 3 ? "pointer" : "",
  });
  const handleClick = () => {
    if (id == 3) {
      //login
    } else if (id === 4) {
      //register
    } else if (id !== -1 && id <= latestProcess) {
      setProcess(id);
    }
    setOpen(false);
  };
  if (id < 3) {
    return (
      <img
        src={require("../../../../local/shell/" + String(id) + ".png").default}
        className={styles.img}
        onClick={() => handleClick()}
        alt=""
      ></img>
    );
  } else if (id === 3) {
    return (
      <ExitToAppIcon className={styles.img} onClick={() => handleClick()} />
    );
  } else {
    return <PersonIcon className={styles.img} onClick={() => handleClick()} />;
  }
}
export default SidebarItem;
