import {Typography} from "@material-ui/core";
import NumberInput from "common/components/NumberInput";
import React from "react";
import {useStyles} from "./styles";
import {MoneyInputProps} from "./types";

function MoneyInput(props: MoneyInputProps) {
  const {onChange, value} = props;
  const styles = useStyles();
  return (
    <div className={styles.inputContainer}>
      <Typography variant="body1" className={styles.inputLabel}>
        ต้องการมีเงินใช้หลังเกษียณเดือนละ
      </Typography>
      <NumberInput value={value} onChange={onChange} unit="บาท" />
    </div>
  );
}

export default MoneyInput;
