import React from "react";
import {PageRoutes} from "hooks/useRouter";
import {RetirementPlanProvider} from "./context/retirementPlanContext";

function RetirementPlan() {
  return (
    <RetirementPlanProvider>
      <PageRoutes />
    </RetirementPlanProvider>
  );
}

export default RetirementPlan;
