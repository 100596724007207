import {useFinanCheckData} from "pages/finanCheck/context/finanCheckContext";
import useRouter from "hooks/useRouter";

const useRiskAssessment = () => {
  const {setPage} = useRouter();
  const {riskAssessment, setRiskAssessment, saveData, plans} =
    useFinanCheckData();
  const {patron, isPrepared} = riskAssessment;

  const setPrepared = (index: number) => {
    return (value: number) => {
      isPrepared[index] = value;
      setRiskAssessment({patron, isPrepared: [...isPrepared]});
    };
  };

  const setPatron = (value: number) => {
    setRiskAssessment({patron: value, isPrepared});
  };

  const isFilled = () => {
    if (patron === -1) return false;
    for (const prepared of isPrepared) {
      if (prepared === -1) return false;
    }
    return true;
  };

  // Handle Submit
  const handleSubmit = () => {
    isPrepared.forEach((val: number, index: number) => {
      if (val == 0) {
        plans.update(index, {selected: true});
      }
    });
    saveData();
    setPage("riskAssessmentResult");
  };

  return {
    isPrepared,
    setPrepared,
    patron,
    setPatron,
    isFilled: isFilled(),
    handleSubmit,
  };
};

export default useRiskAssessment;
