import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "900px",
    margin: "auto",
    marginTop: "20px",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
}));
