import axios from "axios";
import {useState} from "react";

const useAnalytic = (formData: any, temp: any, setTemp: any) => {
  const [analytic, setAnalytic] = useState({
    expected: temp.fund_port.stat.expected,
    sd: temp.fund_port.stat.sd,
    maxdd: -1,
    vcar: -1,
  });

  const analyzePort = async () => {
    let device_id = localStorage.getItem("device_id");
    const url = "https://fhc.insurtechthailand.com/ins/funds/cal";
    const info = {
      device_id: device_id, // device_id ของ local
      cal_type: "port_analyze", // function ที่เรียกใช้
      port: formData.selectedAssetList,
      time: Date.now(),
      calculateAT: new Date().toLocaleString(),
    };
    const response = await axios.post(url, info);
    if (!!response.data.data) {
      const result = response.data.data;
      setAnalytic({...result});
      temp.fund_port.stat.expected = result.expected;
      temp.fund_port.stat.sd = result.sd;
      setTemp({...temp});
    }
  };

  const analyzedData = [
    {
      name: "ผลตอบเทนที่คาดหวัง",
      englishName: "Expected Return",
      value: analytic.expected.toString(),
    },
    {
      name: "ความผันผวน",
      englishName: "(S.D.)",
      value: analytic.sd.toString(),
    },
    {
      name: "ผลตอบแทนขาดทุนสูงสุด",
      englishName: "(Max. Drawdown)",
      value: !!analytic.maxdd ? analytic.maxdd.toString() : "-",
    },
    {
      name: "ค่าความเสี่ยงแบบมีเงื่อนไข",
      englishName: "(CVaR)",
      value: !!analytic.vcar ? analytic.vcar.toString() : "-",
    },
  ];

  return {analyzePort, analyzedData, analytic};
};

export default useAnalytic;
