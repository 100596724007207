import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  column: {
    minWidth: "100%",
    textAlign: "center",
    alignItems: "center",
    minHeight: "40px",
    height: "100%",
    borderRadius: "10px",
    display: "flex",
    alignContent: "center",
    overflow: "hidden",
  },
  backgroundWhite: {
    background: "white",
  },
}));
