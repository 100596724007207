import {makeStyles} from "@material-ui/core";
import color from "config/color";

export const useStyles = makeStyles((theme) => ({
  plan: {
    textAlign: "center",
    padding: "10px",
    backgroundColor: color.blue[100],
    borderRadius: "10px",
  },
  text: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
}));
