import {ClassNameMap} from "@material-ui/core/styles/withStyles";

export const captions = {
  room: "ค่าห้องพัก",
  disease: "ความคุ้มครองโรคร้ายแรง",
  health: "ค่ารักษาพยาบาลแบบเหมาจ่าย",
};

export const chartOptions = (styles: ClassNameMap) => ({
  scales: 1,
  scaleProps: () => ({className: styles.scale, fill: "none"}),
  axisProps: () => ({className: styles.axis}),
  captions: false,
});

export const emptyData = {
  room: 0,
  disease: 0,
  health: 0,
};
