import {Dialog, Typography} from "@material-ui/core";
import React from "react";
import {useStyles} from "./styles";
import AddButton from "common/components/AddButton";
import TextInput from "common/components/TextInput";
import usePlans from "./hooks/useSelectPlans";

const SelectDialog = (props: {open: boolean; onClose: () => void}) => {
  const {onClose, open} = props;
  const styles = useStyles();
  const {handleClick, addPlan, newPlanName, setNewPlanName, plans} = usePlans();

  const choiceList = plans.map((plan) => {
    return (
      <div className={styles.row} key={plan.id}>
        <div className={styles.item}>
          <Typography style={{flexGrow: 1}}>{plan.name}</Typography>
        </div>
        <AddButton
          onClick={() => {
            handleClick(plan.id);
          }}
          style={{marginLeft: "10px"}}
          select={plan.selected}
        />
      </div>
    );
  });

  return (
    <>
      <Dialog classes={{paper: styles.container}} onClose={onClose} open={open}>
        <Typography className={styles.tableHead}>โปรดเพิ่มแผน</Typography>
        <div>
          {choiceList}
          {/* Add New Plan */}
          <div className={styles.row}>
            <TextInput value={newPlanName} onChange={setNewPlanName} />
            <AddButton onClick={addPlan} style={{marginLeft: "10px"}} />
          </div>
        </div>
      </Dialog>
    </>
  );
};
export default SelectDialog;
