import background from "local/healthCare.png";
import {Hidden} from "@material-ui/core";
import {PageRoutes} from "hooks/useRouter";
import {useStyles} from "./styles";

function HealthCare() {
  const styles = useStyles();
  return (
    <>
      <Hidden smDown>
        <img src={background} alt="" className={styles.background} />
      </Hidden>
      <PageRoutes />
    </>
  );
}
export default HealthCare;
