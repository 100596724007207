import {Box, Typography} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import color from "config/color";
import React from "react";
import {useStyles} from "./styles";
import {PlanButtonProps} from "./types";

function PlanButton(props: PlanButtonProps) {
  const {planName, type, setType} = props;
  const styles = useStyles({type});

  return (
    <Box bgcolor={color.grey[100]} borderRadius="20px">
      <Typography
        variant="h3"
        className={styles.caption + " " + styles.planA}
        onClick={() => {
          setType("plan1");
        }}
      >
        <FiberManualRecordIcon fontSize="small" />
        {planName[0]}
      </Typography>
      <Typography
        variant="h3"
        className={styles.caption + " " + styles.planB}
        onClick={() => {
          setType("plan2");
        }}
      >
        <FiberManualRecordIcon fontSize="small" />
        {planName[1]}
      </Typography>
    </Box>
  );
}
export default PlanButton;
