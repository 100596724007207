import {makeStyles} from "@material-ui/core";
import color from "config/color";

export const useStyles = makeStyles(() => ({
  caption: {
    backgroundColor: "white",
    margin: "10px",
    borderRadius: "20px",
    padding: "10px",
    cursor: "pointer",
  },
  planA: {
    color: (props: any) => (props.type === "plan1" ? "white" : color.blue[400]),
    backgroundColor: (props: {type: string}) =>
      props.type === "plan1" ? color.blue[400] : "white",
  },
  planB: {
    color: (props: any) => (props.type == "plan2" ? "white" : color.grey[400]),
    backgroundColor: (props: {type: string}) =>
      props.type === "plan2" ? color.grey[400] : "white",
  },
}));
