import {Hidden, Radio, Typography} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import React from "react";
import {useStyles} from "./styles";
import {QuestionProps} from "./types";

function FormItem(props: QuestionProps) {
  const {text, isPrepared, setPrepared, choices, hideText} = props;
  const styles = useStyles();

  return (
    <Grid container style={{margin: "10px 0 10px 0"}}>
      <Grid item md={8} xs={12}>
        <Typography variant="body1">{text}</Typography>
      </Grid>
      <Grid item md={2} xs={6}>
        <div className={styles.radioContainer}>
          <Radio
            color="primary"
            checked={isPrepared === 1}
            onClick={() => {
              setPrepared(1);
            }}
            className={styles.radio}
          />
          {hideText ? (
            <Hidden mdUp>
              <Typography variant="body2">{choices[0]}</Typography>
            </Hidden>
          ) : (
            <Typography variant="body2">{choices[0]}</Typography>
          )}
        </div>
      </Grid>
      <Grid item md={2} xs={6}>
        <div className={styles.radioContainer}>
          <Radio
            color="primary"
            checked={isPrepared === 0}
            onClick={() => {
              setPrepared(0);
            }}
            className={styles.radio}
          />
          {hideText ? (
            <Hidden mdUp>
              <Typography variant="body2">{choices[1]}</Typography>
            </Hidden>
          ) : (
            <Typography variant="body2">{choices[1]}</Typography>
          )}
        </div>
      </Grid>
    </Grid>
  );
}

export default FormItem;
