import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: "1.25rem",
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      flexShrink: 1,
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    margin: "10px 10px 10px 0px",
    [theme.breakpoints.down("sm")]: {
      margin: "5px 5px 5px 0px",
    },
  },
}));
