import axios from "axios";
import {dataContext} from "common/context/context";
import useRouter from "hooks/useRouter";
import {useRetirementPlanContext} from "pages/retirementPlan/context/retirementPlanContext";
import {useContext, useEffect, useState} from "react";

const useInsurance = () => {
  const {temp, setTemp} = useRetirementPlanContext();
  const [allPlan, setAllPlan] = useState([]);
  const defaultPlan =
    temp.insure_plan.plan_id !== -1 ? temp.insure_plan.plan_id.toString() : "1";
  const [plan, setPlan] = useState(defaultPlan);
  const [formData, setFormData] = useState({
    need_insurance_money: temp.need_insurance_money,
  });
  const {data, setData} = useContext(dataContext);
  const {setPage} = useRouter();

  // Get Data
  const getResult = async () => {
    let device_id = localStorage.getItem("device_id");
    const url = "https://fhc.insurtechthailand.com/ins/retirement/cal";
    if (!!temp) {
      const {
        age,
        gender,
        retriment_age,
        death_age,
        outcome,
        inflationrate,
        retryment_rq,
        risk_data,
        need_insurance,
        risk_accepted,
      } = temp;
      const info = {
        device_id: device_id, // device_id ของ local
        cal_type: "ins_recommend", // function ที่เรียกใช้   ins_recommend , fund_recommend
        sex: gender, //เพศ
        age, //อายุปัจจุบัน
        retriment_age, // defualt 60
        death_age, // defualt 85
        outcome, //รายจ่ายปัจจุบัน
        inflationrate, //อัตราเงินเฟ้อ
        retryment_sh:
          outcome * 0.7 * (1 + inflationrate) ** (retriment_age - age), //เงินบำนาญขั้นต่ำที่ควรมี   =  outcome * 0.7 ((1+(inflation))**(retriment_age - Age))
        retryment_rq, //เงินบำนาญที่ต้องการรมี  ระบุเอง
        risk_form_id: risk_data.risk_form_id, //not edit  default จาก response.risk_form_id
        risk_assessed: risk_data.risk_assessed, //not edit  default จาก response.risk_assessed
        risk_accepted, //ความเสี่ยงจากการลงทุนที่รับได้ default จาก response.risk_assessed   แต่เลือกเปลี่ยนเองได้
        need_insurance: need_insurance, //  0 - 100 need_insurance + need_mutualfund =  100
        need_mutualfund: 100 - need_insurance, //  0 - 100 need_insurance + need_mutualfund =  100
        time: Date.now(),
        calculateAT: new Date().toLocaleString(),
      };
      const response = await axios.post(url, info);
      if (!!response.data.ins_plans) {
        setAllPlan(response.data.ins_plans);
      }
    }
  };
  useEffect(() => {
    getResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Change Plan
  const handlePlanChange = (plan_order: number) => () => {
    temp.insure_plan = allPlan[plan_order];
    setPlan(String(plan_order + 1));
  };

  // Change Page
  const handlePageChange = (page: string) => () => {
    if (temp.insure_plan.plan_id === -1) {
      temp.insure_plan = allPlan[0];
    }
    setTemp({
      ...temp,
      need_insurance_money: formData.need_insurance_money,
    });
    setData({
      ...data,
      retirePlan: {
        ...temp,
        need_insurance_money: formData.need_insurance_money,
      },
    });
    setPage(page);
  };

  // Insurance Money
  const insuranceMoney = formData.need_insurance_money;
  const handleMoneyChange = (need_insurance_money: number) => {
    setFormData({...formData, need_insurance_money});
  };

  return {
    allPlan,
    insuranceMoney,
    handleMoneyChange,
    handlePlanChange,
    plan,
    handlePageChange,
  };
};

export default useInsurance;
