import {IconButton} from "@material-ui/core";
import React from "react";
import {TiPlus} from "react-icons/ti";
import {useStyles} from "./styles";
import {AddFundProps} from "./types";

const AddFundButton = ({addFund}: AddFundProps) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <IconButton
        color="primary"
        className={styles.plusButton}
        onClick={addFund}
      >
        <TiPlus color="white" />
      </IconButton>
    </div>
  );
};

export default AddFundButton;
