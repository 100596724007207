import {useFinanCheckData} from "pages/finanCheck/context/finanCheckContext";
import {useState} from "react";

const useSelectPlans = () => {
  const {plans} = useFinanCheckData();

  const handleClick = (id: number) => {
    const plan = plans.get(id);
    if (plan.selected) {
      plans.remove(id);
    } else {
      plans.update(id, {selected: true});
    }
  };

  const [newPlanName, setNewPlanName] = useState("");

  const addNewPlan = () => {
    plans.add(newPlanName);
    setNewPlanName("");
  };

  return {
    handleClick,
    addPlan: addNewPlan,
    newPlanName,
    setNewPlanName,
    plans: plans.getAll(),
  };
};

export default useSelectPlans;
