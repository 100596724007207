import {Grid} from "@material-ui/core";
import TableTypography from "pages/finanCheck/components/TableTypography";
import {ResultItemProps} from "./types";

function ResultItem(props: ResultItemProps) {
  const {name, prepareMoney, useMoney} = props;
  return (
    <>
      <Grid container spacing={1} style={{marginBottom: "5px"}}>
        <TableTypography md={6} xs={12}>
          {name}
        </TableTypography>
        <TableTypography md={2} xs={4}>
          {useMoney.toLocaleString()}
        </TableTypography>
        <TableTypography md={2} xs={4}>
          {prepareMoney.toLocaleString()}
        </TableTypography>
        <TableTypography md={2} xs={4}>
          {Math.max(0, useMoney - prepareMoney).toLocaleString()}
        </TableTypography>
      </Grid>
    </>
  );
}
export default ResultItem;
