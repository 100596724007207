import {makeStyles} from "@material-ui/core";
import color from "config/color";

export const useStyles = makeStyles((theme) => ({
  name: {
    position: "absolute",
    right: 0,
    bottom: "85%",
    padding: "10px",
    borderRadius: "10px",
    backgroundColor: color.grey[100],
    zIndex: 5,
  },
}));
