import React from "react";
import Cell from "./components/Cell";
import useDataTable from "./hooks/useDataTable";
import {TableProps} from "./props";
import {useStyles} from "./styles";

function Table(props: TableProps) {
  const {tableKey, label, data, type, cellStyles, highlight} = props;

  const styles = useStyles();

  const {getDataTable} = useDataTable();
  const {dataTable, highlightTable} = getDataTable(
    {tableKey, label, data, highlight},
    type || "row"
  );

  return (
    <>
      {dataTable.map((row, i) => {
        return (
          <div className={styles.rowContainer} key={i}>
            {row.map((value, j) => {
              return (
                <Cell
                  index={{
                    row: i,
                    column: j,
                    maxRow: dataTable.length,
                    maxColumn: dataTable[0].length,
                  }}
                  key={j}
                  highlight={highlightTable[i][j]}
                  cellStyle={cellStyles}
                >
                  {value}
                </Cell>
              );
            })}
          </div>
        );
      })}
    </>
  );
}

export default Table;
