import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: "flex",
    background: "white",
    borderRadius: "10px",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  input: {
    flexGrow: 1,
    textAlign: "center",
    alignItems: "center",
    minHeight: "40px",
    height: "100%",
    paddingLeft: "5px",
    overflow: "hidden",
  },
  unit: {
    flexShrink: 0,
    padding: "0 5px 0 5px",
  },
}));
