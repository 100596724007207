import {DialogContent, Modal} from "@material-ui/core";
import React, {useCallback, useState} from "react";

function useDialog(
  Component: (props: {closeDialog: () => void}) => JSX.Element
) {
  const [open, setOpen] = useState(false);
  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);
  const closeDialog = useCallback(() => {
    setOpen(false);
  }, []);

  const Dialog = (props?: {[key: string]: any}) => {
    return (
      <Modal
        open={open}
        onClose={closeDialog}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DialogContent>
          <Component closeDialog={closeDialog} {...props} />
        </DialogContent>
      </Modal>
    );
  };

  return {openDialog, closeDialog, Dialog};
}

export default useDialog;
