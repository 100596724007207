import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "900px",
    margin: "auto",
    marginTop: "20px",
    padding: "10px",
    [theme.breakpoints.down("md")]: {
      padding: "0px",
    },
  },
  content: {
    marginTop: "20px",
  },
  roomCost: {
    position: "absolute",
    textAlign: "center",
    width: "100%",
    top: 0,
  },
  seriousIllnessCost: {
    position: "absolute",
    left: "5%",
    bottom: "15%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      left: "0",
    },
  },
  packageCost: {
    position: "absolute",
    right: "5%",
    bottom: "15%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      right: "0",
    },
  },
}));
