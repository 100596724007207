import {dataContext} from "common/context/context";
import {createContext, useCallback, useContext, useState} from "react";
import {initialData} from "../constants";
import {IRiskAssessment} from "../types";
import usePlans from "./hooks/usePlans";
import {IFinanCheckContext} from "./types";

export const financheckContext = createContext({} as IFinanCheckContext);

export const useFinanCheckData = () => useContext(financheckContext);

export const FinanCheckProvider = (props: React.PropsWithChildren<{}>) => {
  const {data, setData} = useContext(dataContext);
  const plans = usePlans(data.finanCheck?.plans);
  const [riskAssessment, setRiskAssessment] = useState<IRiskAssessment>(
    data.finanCheck?.riskAssessment || initialData.riskAssessment
  );

  const saveData = useCallback(() => {
    setData({...data, finanCheck: {plans: plans.getAll(), riskAssessment}});
  }, [data, plans, riskAssessment, setData]);

  return (
    <financheckContext.Provider
      value={{
        plans,
        riskAssessment,
        setRiskAssessment,
        saveData,
      }}
    >
      {props.children}
    </financheckContext.Provider>
  );
};
