import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  score: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "150px",
    height: "150px",
    borderRadius: "500px",
    border: "5px solid green",
    marginBottom: "20px",
  },
  scoreContainer: {
    margin: "50px 0 50px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    display: "block",
    margin: "auto",
    marginTop: "30px",
  },
}));
