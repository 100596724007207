import withFormik from "common/hoc/withFormik";
import React from "react";
import {GenderButton, useStyles} from "./styles";
import {GenderButtonProps} from "./types";

function GenderInput(props: GenderButtonProps) {
  const {onChange, value} = props;
  const styles = useStyles();

  return (
    <>
      <GenderButton
        onClick={() => onChange("male")}
        className={value === "male" ? styles.selected : ""}
        style={{
          borderRadius: "10px 0px 0px 10px",
        }}
      >
        ชาย
      </GenderButton>
      <GenderButton
        onClick={() => onChange("female")}
        className={value === "female" ? styles.selected : ""}
        style={{
          borderRadius: "0px 10px 10px 0px",
        }}
      >
        หญิง
      </GenderButton>
    </>
  );
}

export default withFormik(GenderInput);
