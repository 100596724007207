import {Typography} from "@material-ui/core";
import React from "react";
import {useStyles} from "./styles";
import {FormContainerProps} from "./types";

function FormContainer(props: FormContainerProps) {
  const styles = useStyles();
  const {children, name, contentStyle, style} = props;

  return (
    <div className={styles.formContainer} style={style}>
      <Typography className={styles.formHead}>{name}</Typography>
      <div className={styles.formContent} style={contentStyle}>
        {children}
      </div>
    </div>
  );
}
export default FormContainer;
