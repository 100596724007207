import Typography from "@material-ui/core/Typography";
import Button from "common/components/Button";
import React from "react";
import {useStyles} from "./styles";
import {SavingChoiceProps} from "./types";

const SavingChoiceButton = ({changePage}: SavingChoiceProps) => {
  const styles = useStyles();
  return (
    <div className={styles.buttonContainer}>
      <Typography variant="h3" style={{margin: "10px"}}>
        ทางเลือกในการออม
      </Typography>
      <Button
        type="submit"
        className={styles.button}
        onClick={() => {
          changePage("insurance");
        }}
      >
        ประกันชีวิต
      </Button>
      <Button
        type="submit"
        className={styles.button}
        onClick={() => {
          changePage("mutualFund");
        }}
      >
        กองทุนรวม
      </Button>
    </div>
  );
};

export default SavingChoiceButton;
