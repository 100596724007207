import {
  Box,
  Button,
  Hidden,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import React, {useState} from "react";
import MenuIcon from "@material-ui/icons/Menu";
import MenuDrawer from "./MenuDrawer";
import color from "config/color";
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: color.blue[400],
    color: "white",
    textAlign: "center",
    padding: "10px",
  },
  buttonContainer: {
    position: "absolute",
    right: "10px",
    display: "inline",
  },
  menu: {
    position: "absolute",
    left: "10px",
    marginTop: "-1px",
    display: "inline",
    fontSize: "2rem",
  },
  button: {
    textTransform: "none",
    color: "white",
    fontWeight: 300,
    "&:hover": {
      color: color.blue[500],
      background: "none",
    },
  },
  typo: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "30px",
    },
  },
}));
function Header() {
  const styles = useStyles();
  const [isOpen, setOpen] = useState(false);
  return (
    <div className={styles.container}>
      <Hidden mdUp>
        <MenuIcon className={styles.menu} onClick={() => setOpen(true)} />
      </Hidden>
      <Hidden smDown>
        <Box className={styles.buttonContainer}>
          <Button className={styles.button} startIcon={<ExitToAppIcon />}>
            Login
          </Button>
          <Button className={styles.button} startIcon={<PersonIcon />}>
            ลงทะเบียน
          </Button>
        </Box>
      </Hidden>
      <Typography variant="h2" className={styles.typo}>
        โปรแกรมวางแผนการเงิน
      </Typography>
      <MenuDrawer isOpen={isOpen} setOpen={setOpen} />
    </div>
  );
}
export default Header;
