import {Box, Grid, Typography} from "@material-ui/core";
import React from "react";
import Card from "./components/Card";
import {useStyles} from "./styles";

function Home() {
  const styles = useStyles();
  return (
    <>
      <Box className={styles.container}>
        <Typography
          variant="h1"
          style={{marginTop: "10px", marginBottom: "20px"}}
        >
          เลือกการวางแผนที่เหมาะสมกับคุณ
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card menu="finanCheck" />
              </Grid>
              <Grid item xs={6}>
                <Card menu="healthCare" />
              </Grid>
              <Grid item xs={6}>
                <Card menu="retirementPlan" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card menu="sumAssured" />
              </Grid>
              <Grid item xs={6}>
                <Card menu="educationPlan" />
              </Grid>
              <Grid item xs={6}>
                <Card menu="legacyPlan" />
              </Grid>
              <Grid item xs={6}>
                <Card menu="taxPlan" />
              </Grid>
              <Grid item xs={6}>
                <Card menu="savingPlan" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
export default Home;
