import {Grid, Hidden, Typography} from "@material-ui/core";
import React from "react";
import NumberInput from "common/components/NumberInput";
import {useFinanCheckData} from "../../context/finanCheckContext";
import {useStyles} from "./styles";
import {PlanInputProps} from "./types";
import TableTypography from "../TableTypography";

function FinanCheckItem(props: PlanInputProps) {
  const styles = useStyles();
  const {plan, inputKey} = props;
  const {plans} = useFinanCheckData();
  const handleChange = (value: number) => {
    plans.update(plan.id, {[inputKey]: value});
  };

  return (
    <>
      <Grid container spacing={1} style={{marginBottom: "5px"}}>
        <TableTypography xs={6}>{plan.name}</TableTypography>
        <Grid item md={5} xs={6}>
          <NumberInput
            onChange={handleChange}
            type="int"
            value={plan[inputKey] || 0}
          />
        </Grid>
        <Hidden smDown>
          <Grid item xs={1}>
            <Typography className={styles.column}>บาท</Typography>
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
}
export default FinanCheckItem;
