import {Box, Typography} from "@material-ui/core";
import React from "react";
import {FormItemProps} from "./types";
import {useStyles} from "./styles";

function FormItem(props: FormItemProps) {
  const styles = useStyles();
  const {label, input, width} = props;
  return (
    <Box className={styles.container} width={`min(${width}, 100%)`}>
      <Typography
        variant="body1"
        style={{marginRight: "15px"}}
        className={styles.label}
      >
        {label}
      </Typography>
      {input}
    </Box>
  );
}

export default FormItem;
