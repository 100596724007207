import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  button: {
    fontFamily: "Mitr",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderRadius: "10px",
    padding: "5px 20px 5px 20px",
    fontSize: "1.25rem",
    margin: "0 5px 0 5px",
    boxShadow: "0px 1px 3px #00000055",
    minWidth: "120px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      margin: "5px",
    },
  },
  disabled: {
    backgroundColor: "#a6a6a6",
  },
  center: {
    display: "block",
    margin: "auto",
  },
}));
