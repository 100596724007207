import color from "config/color";
import {IPlanList} from "pages/healthCare/types";
import {useCallback, useState} from "react";
import {emptyData} from "../constants";

const useResultData = ({data, scale}: IPlanList) => {
  const [type, setType] = useState<"initial" | "plan1" | "plan2">("initial");

  const getData = useCallback(
    (data) => {
      return {
        room: data.room / scale.room,
        disease: (data.disease || 1) / (scale.disease || 1),
        health: data.health / scale.health,
      };
    },
    [scale.disease, scale.health, scale.room]
  );

  return {
    data: [
      {
        data: type === "plan2" ? emptyData : getData(data[0]),
        meta: {color: `${color.blue[200]}88`},
      },
      {
        data: type === "plan1" ? emptyData : getData(data[1]),
        meta: {color: `${color.grey[200]}88`},
      },
    ],
    setType,
    type,
  };
};

export default useResultData;
