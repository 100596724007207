import {Grid, Typography} from "@material-ui/core";
import FormContainer from "common/components/FormContainer";
import GenderInput from "common/components/GenderInput";
import NumberInput from "common/components/NumberInput";
import {Formik, Form} from "formik";
import React from "react";
import FormItem from "./components/FormItem";
import SelectInput from "common/components/SelectInput";
import Button from "common/components/Button";
import useForm from "./hooks/useForm";
import {useStyles} from "./styles";

function HealthCareForm() {
  const styles = useStyles();
  const {initialValues, handleSubmit} = useForm();

  return (
    <div className={styles.container}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <Typography variant="h1" color="primary">
            ประกันสุขภาพระยะยาว
          </Typography>
          <FormContainer name="กรอกข้อมูลสำคัญ">
            <Grid container spacing={2}>
              <FormItem
                label="เพศ"
                variant="small"
                input={<GenderInput name="sex" />}
              />
              <FormItem
                label="อายุ"
                variant="small"
                input={<NumberInput name="age" unit="ปี" />}
              />
              <FormItem
                label="ประเภทโรงพยาบาลที่ใช้บริการ"
                variant="large"
                input={
                  <SelectInput
                    name="hospital"
                    options={[
                      {value: "Premium", text: "Premium"},
                      {value: "เอกชน", text: "โรงพยาบาลเอกชน"},
                      {value: "รัฐบาล", text: "โรงพยาบาลรัฐบาล"},
                    ]}
                  />
                }
              />
              <FormItem
                label="ความคุ้มครองโรคร้ายแรงที่ต้องการเตรียม"
                variant="large"
                input={<NumberInput name="disease" unit="บาท" />}
              />
            </Grid>
          </FormContainer>
          <Button style={{float: "right"}} type="submit">
            ต่อไป
          </Button>
        </Form>
      </Formik>
    </div>
  );
}
export default HealthCareForm;
