import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  background: {
    bottom: 0,
    right: 0,
    height: "min(70vh, 70vw)",
    zIndex: -1,
    position: "fixed",
  },
}));
