import axios from "axios";
import {useRetirementPlanContext} from "pages/retirementPlan/context/retirementPlanContext";
import {useEffect, useState} from "react";

const useResult = () => {
  const [fetchData, setFetchData] = useState({
    need_savings: 53333,
    retryment_rq: 30000,
  });
  const {temp} = useRetirementPlanContext();
  const getResult = async () => {
    let device_id = localStorage.getItem("device_id");
    const url = "https://fhc.insurtechthailand.com/ins/retirement/summary";
    if (!!temp) {
      const {
        age,
        gender,
        retriment_age,
        death_age,
        outcome,
        inflationrate,
        retryment_rq,
        risk_data,
        need_insurance,
        risk_accepted,
        insure_plan,
        fund_port,
      } = temp;
      const info = {
        device_id: device_id, // device_id ของ local
        cal_type: "calculate", // function ที่เรียกใช้   ins_recommend , fund_recommend
        sex: gender, //เพศ
        age, //อายุปัจจุบัน
        retriment_age, // defualt 60
        death_age, // defualt 85
        outcome, //รายจ่ายปัจจุบัน
        inflationrate, //อัตราเงินเฟ้อ
        retryment_sh:
          outcome * 0.7 * (1 + inflationrate) ** (retriment_age - age), //เงินบำนาญขั้นต่ำที่ควรมี   =  outcome * 0.7 ((1+(inflation))**(retriment_age - Age))
        retryment_rq, //เงินบำนาญที่ต้องการรมี  ระบุเอง
        risk_form_id: risk_data.risk_form_id, //not edit  default จาก response.risk_form_id
        risk_assessed: risk_data.risk_assessed, //not edit  default จาก response.risk_assessed
        risk_accepted, //ความเสี่ยงจากการลงทุนที่รับได้ default จาก response.risk_assessed   แต่เลือกเปลี่ยนเองได้
        need_insurance: need_insurance, //  0 - 100 need_insurance + need_mutualfund =  100
        need_mutualfund: 100 - need_insurance, //  0 - 100 need_insurance + need_mutualfund =  100
        select_plan: insure_plan,
        fund_port,
        time: Date.now(),
        calculateAT: new Date().toLocaleString(),
      };
      const response = await axios.post(url, info);
      if (!!response.data.data) {
        setFetchData(response.data.data);
      }
    }
  };

  useEffect(() => {
    getResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {fetchData};
};

export default useResult;
