import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  cell: {
    border: "1px solid white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));
