import React from "react";
import {ButtonBase} from "@material-ui/core";
import {useStyles} from "./styles";
import {ButtonProps} from "./types";

function Button(props: ButtonProps) {
  const styles = useStyles();
  const {children, className, center, ...attributes} = props;

  return (
    <ButtonBase
      className={`${styles.button} 
      ${attributes.disabled && styles.disabled} 
      ${center && styles.center} 
      ${className}`}
      {...attributes}
    >
      {children}
    </ButtonBase>
  );
}

export default Button;
