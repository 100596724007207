export const risk_options = [
  {
    value: "0",
    text: "",
  },
  {
    value: "1",
    text: "ความเสี่ยงต่ำ",
  },
  {
    value: "2",
    text: "ความเสี่ยงปานกลางค่อนข้างต่ำ",
  },
  {
    value: "3",
    text: "ความเสี่ยงปานกลางค่อนข้างสูง",
  },
  {
    value: "4",
    text: "ความเสี่ยงสูง",
  },
  {
    value: "5",
    text: "ความเสี่ยงสูงมาก",
  },
];
