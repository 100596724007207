import CustomMutualFund from "pages/retirementPlan/pages/customMutualFund";
import Form from "pages/retirementPlan/pages/form";
import Insurance from "pages/retirementPlan/pages/insurance";
import MutualFund from "pages/retirementPlan/pages/mutualFund";
import Result from "pages/retirementPlan/pages/result";
import {IPageRoutes} from "routes";

const pageRoutes: IPageRoutes = {
  default: {
    component: Form,
    type: "form",
  },
  form: {
    component: Form,
    type: "form",
  },
  mutualFund: {
    component: MutualFund,
    type: "form",
  },
  result: {
    component: Result,
    type: "result",
  },
  insurance: {
    component: Insurance,
    type: "result",
  },
  customMutualFund: {
    component: CustomMutualFund,
    type: "result",
  },
};
export default pageRoutes;
