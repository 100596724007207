import {useRetirementPlanContext} from "../context/retirementPlanContext";

const useExpectReward = () => {
  const {temp} = useRetirementPlanContext();
  const {fund_port} = temp;
  const expenseY = temp.need_mutualfund_money * 12;
  const expectreturn = fund_port.stat.expected / 100;
  const period = temp.retriment_age - temp.age;
  //Expense Year at retire age
  const expenseRetire =
    expenseY * Math.pow(1 + temp.inflationrate / 100, period);
  const budgetRetire = expenseRetire * (temp.death_age - temp.retriment_age);
  const savingY =
    budgetRetire * (expectreturn / (Math.pow(1 + expectreturn, period) - 1));
  const data = [];
  let A = savingY;
  for (let i = 1; i <= period; i++) {
    const year = [];
    year.push(i);
    year.push(i + temp.age);
    year.push(
      expectreturn.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
    A = savingY + A * (1 + expectreturn);
    year.push(
      A.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
    data.push(year);
  }
  return data;
};

export default useExpectReward;
