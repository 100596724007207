import axios from "axios";
import {dataContext} from "common/context/context";
import {v4} from "uuid";
import {useCallback, useContext, useEffect, useState} from "react";
import {IPlanList} from "pages/healthCare/types";

export const useResult = () => {
  const {data} = useContext(dataContext);
  const healthCareData = data.healthCare;

  const [planList, setPlanList] = useState<IPlanList | null>(null);

  let device_id = localStorage.getItem("device_id");
  if (!device_id) {
    device_id = v4();
    localStorage.setItem("device_id", device_id);
  }

  const getResult = useCallback(async () => {
    // TODO use axios instance
    const url = "https://fhc.insurtechthailand.com/ins/healthcare/cal";
    if (!healthCareData) return;
    try {
      const info = {
        cal_type: "longtermhealthcare",
        device_id,
        ...healthCareData,
        time: Date.now(),
        calculateAT: new Date().toLocaleString(),
      };

      const {data} = await axios.post(url, info);

      // TODO what is this???
      data.data[0].disease = healthCareData.disease;
      data.data[1].disease = healthCareData.disease;

      setPlanList(data);
    } catch (err) {
      // TODO handle error
    }
  }, [device_id, healthCareData]);

  useEffect(() => {
    getResult();
  }, [getResult]);

  return planList;
};
