import {makeStyles} from "@material-ui/core";
import color from "config/color";

export const useStyles = makeStyles((theme) => ({
  summaryValue: {
    display: "flex",
    backgroundColor: "white",
    borderRadius: "10px",
    width: "70%",
    padding: "10px",
    margin: "auto",
  },
  summaryContainer: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: color.grey[100],
    borderRadius: "20px",
    padding: "0 10px 10px 10px",
    marginTop: "20px",
  },
  savingPlanTitle: {
    backgroundColor: color.blue[400],
    color: "white",
    padding: "10px",
    borderRadius: "10px",
    textAlign: "center",
  },
}));
