import {InputBase, Typography} from "@material-ui/core";
import withFormik from "common/hoc/withFormik";
import React from "react";
import useNumberInput from "./hooks/useNumberInput";
import {useStyles} from "./styles";
import {NumberInputProps} from "./types";

function NumberInput(props: NumberInputProps) {
  const {unit, className, style, onChange, value, field, type} = props;

  const styles = useStyles();

  const {handleChange, text} = useNumberInput(value, type || "int", onChange);

  return (
    <div className={styles.inputContainer + " " + className} style={style}>
      <InputBase
        className={styles.input}
        inputProps={{
          min: 0,
          style: {textAlign: "center"},
        }}
        multiline
        type="number"
        {...field}
        onChange={handleChange}
        value={text}
      />
      {unit && <Typography className={styles.unit}>{unit}</Typography>}
    </div>
  );
}

export default withFormik(NumberInput);
