import {Grid, Typography} from "@material-ui/core";
import Button from "common/components/Button";
import FormContainer from "common/components/FormContainer";
import color from "config/color";
import React from "react";
import useExpectReward from "../../hooks/useExpectReward";
import {useStyles} from "./styles";
import {InvestmentProps} from "./types";

function Investment(props: InvestmentProps) {
  const {closeDialog} = props;
  const data = useExpectReward();
  const styles = useStyles();
  const labels = [
    "ปี",
    "อายุ",
    "อัตราผลตอบแทนที่คาดหวัง",
    "มูลค่าการลงทุนที่คาดหวัง",
  ];

  const Cell = (props: {text: string | number; style: React.CSSProperties}) => (
    <Grid item xs={6} md={3}>
      <div className={styles.cell} style={props.style}>
        <Typography style={{textAlign: "center"}}>{props.text}</Typography>
      </div>
    </Grid>
  );

  const Row = (props: {
    data: (string | number)[];
    style: React.CSSProperties;
  }) => (
    <Grid container>
      {props.data.map((value, idx) => (
        <Cell text={value} style={props.style} key={idx} />
      ))}
    </Grid>
  );

  const ExpectValue = data.map((year, idx) => (
    <Row data={year} style={{backgroundColor: color.grey[100]}} key={idx} />
  ));

  return (
    <FormContainer
      name="มูลค่าการลงทุนที่คาดหวัง"
      style={{width: "95%"}}
      contentStyle={{
        backgroundColor: "white",
        maxHeight: "80vh",
        overflowY: "scroll",
      }}
    >
      <div style={{margin: "10px 0 10px 0"}}>
        <Row
          data={labels}
          style={{color: "white", backgroundColor: color.blue[400]}}
        />
        {ExpectValue}
      </div>
      <Button style={{display: "block", margin: "auto"}} onClick={closeDialog}>
        กลับหน้าแสดงผล
      </Button>
    </FormContainer>
  );
}

export default Investment;
