import {Button, makeStyles, withStyles} from "@material-ui/core";
import color from "config/color";

export const GenderButton = withStyles({
  root: {
    width: "50%",
    minHeight: "40px",
    borderRadius: "0",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
  },
})(Button);

export const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: color.blue[100],
    "&:hover": {
      backgroundColor: color.blue[100],
    },
  },
}));
