import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  planButtonContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  planButton: {
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
