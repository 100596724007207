import {Typography} from "@material-ui/core";
import color from "config/color";
import React from "react";
import FundChart from "../../../../components/FundChart";
import {useStyles} from "./styles";
import {PortProps} from "./types";

function Port(props: PortProps) {
  const {
    risk,
    percent,
    expectedReturn,
    SD,
    recommend,
    selected,
    port_id,
    onClick,
  } = props;

  const styles = useStyles();

  return (
    <div
      className={styles.container}
      style={{
        border: selected ? "3px solid #284893" : `3px solid ${color.grey[100]}`,
      }}
      key={port_id}
      onClick={onClick}
    >
      <Typography variant="body1" className={styles.title}>
        {risk}
      </Typography>
      <div className={styles.data}>
        <div className={styles.chart}>
          <FundChart data={percent} />
        </div>
        <div>
          <Content
            thaiName="ผลตอบเทนที่คาดหวัง"
            englishName="Expected Return"
            value={expectedReturn}
          />
          <Content thaiName="ความผันผวน" englishName="(S.D.)" value={SD} />
        </div>
      </div>
      {recommend && (
        <Typography color="primary" style={{margin: "10px 0 10px 0"}}>
          พอร์ตที่แนะนำ
        </Typography>
      )}
    </div>
  );
}

const Content = (props: {
  thaiName: string;
  englishName: string;
  value: string | number;
}) => {
  const {thaiName, englishName, value} = props;
  return (
    <div style={{marginTop: "1rem", textAlign: "center"}}>
      <Typography variant="body2" style={{marginBottom: "-2px"}}>
        {thaiName}
      </Typography>
      <Typography variant="body2">{englishName}</Typography>
      <Typography variant="body2" style={{marginTop: "1rem"}}>
        {value}
      </Typography>
    </div>
  );
};

export default Port;
