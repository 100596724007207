import {Typography} from "@material-ui/core";
import NumberInput from "common/components/NumberInput";
import color from "config/color";
import React from "react";
import {useStyles} from "./styles";
import {AgeInputProps} from "./types";

const Line = (props: {style: React.CSSProperties}) => {
  const styles = useStyles();
  return <div className={styles.line} style={props.style} />;
};
const Dot = () => {
  const styles = useStyles();
  return <div className={styles.dot} />;
};

function AgeInput(props: AgeInputProps) {
  const {start, end, label, name} = props;
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.lineContainer}>
        <Line style={{backgroundColor: start ? "" : color.grey[300]}} />
        <Dot />
        <Line style={{backgroundColor: end ? "" : color.grey[300]}} />
      </div>
      <Typography variant="body1" style={{margin: "10px 0 10px 0"}}>
        {label}
      </Typography>
      <NumberInput name={name} unit="ปี" className={styles.numberInput} />
    </div>
  );
}

export default AgeInput;
