import {makeStyles} from "@material-ui/core";
import color from "config/color";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  lineContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  line: {
    flexGrow: 1,
    height: "3px",
  },
  dot: {
    width: "20px",
    height: "20px",
    borderRadius: "10px",
    backgroundColor: color.grey[300],
    [theme.breakpoints.down("sm")]: {
      width: "15px",
      height: "15px",
    },
  },
  numberInput: {
    width: "120px",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90px",
    },
  },
}));
