import {makeStyles} from "@material-ui/core";
import color from "config/color";

export const useStyles = makeStyles((theme) => ({
  item: {
    flexGrow: 1,
    textAlign: "center",
    alignItems: "center",
    minHeight: "40px",
    borderRadius: "10px",
    display: "flex",
    paddingLeft: "10px",
    paddingRight: "10px",
    overflow: "hidden",
    background: "white",
  },
  row: {
    display: "flex",
    margin: "10px",
  },
  container: {
    width: "500px",
    backgroundColor: color.grey[100],
  },
  tableHead: {
    backgroundColor: color.blue[400],
    padding: "10px",
    color: "white",
    fontSize: "1.5rem",
    textAlign: "center",
  },
}));
