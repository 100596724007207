import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    cursor: "pointer",
    borderRadius: "40px",
    [theme.breakpoints.down("md")]: {
      borderRadius: "20px",
    },
  },
}));
