import {Typography} from "@material-ui/core";
import color from "config/color";
import React from "react";
import {useStyles} from "./styles";
import {AnalyzePortTableProps} from "./types";

function AnalyzePortTable(props: AnalyzePortTableProps) {
  const {data} = props;
  const styles = useStyles();
  return (
    <div className={styles.container}>
      {data.map(({name, englishName, value}) => (
        <div key={name} className={styles.item}>
          <div className={styles.cell + " " + styles.name} style={{}}>
            <Typography variant="body1">{name}</Typography>
            <Typography variant="body1">{englishName}</Typography>
          </div>
          <div
            className={styles.cell}
            style={{backgroundColor: color.grey[100]}}
          >
            <Typography variant="body1">
              {value === "-1" ? "-" : value}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  );
}

export default AnalyzePortTable;
